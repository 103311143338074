<template>

    <div class="all">
        <a-config-provider :locale="locale">
        <div class="aicreat-container">
           
            <a-flex vertical class="aicreat-all-wrap">
                <div class="aicreat-title bg-card">
                    <div>
                        <img src="./../../assets/video.png" alt="">
                        <span class="main-title">AIET数字人</span> 
                    </div>

                    <div>
                        <span style="margin-right:12px">可使用分钟数：{{videoMinute}}</span>
                        <span @click="openTask" class="task-btn"><ContainerFilled style="color:#4e79fa;font-size:15px" /> 任务列表</span>
                        <a-dropdown v-if="isLogin&&isLogin!=undefined"  placement="bottomRight">
                            <a class="ant-dropdown-link" @click.prevent>
                            <UserOutlined style="color:#4e79fa;font-size:15px" /> <span style="color:#323335">{{username}}<DownOutlined /></span>
                            
                            </a>
                            <template #overlay>
                            <a-menu>
                                <a-menu-item>
                                <a href="javascript:;" @click="openMember">会员充值</a>
                                </a-menu-item>
                                <a-menu-item>
                                <a href="javascript:;" @click="quitLogin">退出登录</a>
                                </a-menu-item>
                            </a-menu>
                            </template>
                        </a-dropdown>
                        <span style="cursor:pointer" @click="quitLogin" v-else>账号登录</span>
                    </div>
                    
                </div>
                <a-flex  class="aicreat-all-wrap">
                    <a-flex class="ai-left-wrap">
                        <a-flex vertical align="center" class="aicreat-all-wrap aicreat-menu bg-card" >
                            <div :class="index==1?'bg-white menu-item':'menu-item'" @click="clickMenu(1)">
                                <span class="aic-sprite-icon menu1" alt=""></span>
                                <span>AI文案</span>
                            </div>
                            <!-- <div :class="index==2?'bg-white menu-item':'menu-item'" @click="clickMenu(2)">
                                <span class="aic-sprite-icon menu2" alt=""></span>
                                <span>视频</span>
                            </div> -->
                            <div :class="index==3?'bg-white menu-item':'menu-item'" @click="clickMenu(3)">
                                <span class="aic-sprite-icon menu3"  alt=""></span>
                                <span>数字人</span>
                            </div>
                            <div :class="index==4?'bg-white menu-item':'menu-item'" @click="clickMenu(4)">
                                <span class="aic-sprite-icon menu4"  alt=""></span>
                                <span>配音</span>
                            </div>
                            <!-- <div :class="index==5?'bg-white menu-item':'menu-item'" @click="clickMenu(5)">
                                <span class="aic-sprite-icon menu5" alt=""></span>
                                <span>音乐</span>
                            </div>
                            <div :class="index==6?'bg-white menu-item':'menu-item'" @click="clickMenu(6)">
                                <span class="aic-sprite-icon menu6" alt=""></span>
                                <span>字幕</span>
                            </div> -->
                        </a-flex>
                        <div class="aicreat-config-wrap">
                            <!-- 脚本 -->
                            <div v-if="index==1" class="copywriting-content">
                                <div class="config-title">脚本文案</div>
                                <div class="copywriting-menu">
                                    <a-row :gutter="16">
                                        <a-col class="gutter-row" :span="12">
                                            <div class="copywriting-menu-item copywriting1" @click="openAitext">
                                                <div class="copywriting-menu-item-text">
                                                    <span>AI生成脚本</span>
                                                    <p>输入产品信息快捷生成脚本并填充</p>
                                                </div>
                                            </div>
                                        </a-col>
                                        <a-col class="gutter-row" :span="12">
                                            <div class="copywriting-menu-item copywriting2" @click="openAilist">
                                                <div class="copywriting-menu-item-text">
                                                    <span>脚本库</span>
                                                    <p>从历史生成保存的AI脚本中选择并填充</p>
                                                </div>
                                            </div>
                                        </a-col>
                                    </a-row>
                                </div>
                                <div class="copywriting-input">
                                    <a-textarea show-count :bordered="false" v-model:value="data.materials.script.scriptContent" placeholder="请输入脚本"  :maxlength="600" />
                                    <!-- <div class="copywriting-btn">
                                        <a-button type="primary">确定</a-button>
                                        <a-button type="primary">确定</a-button>
                                    </div> -->
                                </div>
                            </div>

                            <!-- 视频 -->
                            <div  v-if="index==2" class="digital-content">

                                



<!--                                
                                <div class="ant-tabs ant-tabs-top css-dev-only-do-not-override-3m4nqy" style="height: auto;">
                                    <div role="tablist" class="ant-tabs-nav" >
                                        <div class="ant-tabs-nav-wrap">
                                            <div class="ant-tabs-nav-list" style="transform: translate(0px, 0px);">
                                                
                                            </div>
                                        </div>
                                        <div class="ant-tabs-extra-content">
                                            <span class="clear-btn">清空选项</span>
                                        </div>
                                    </div>
                                </div> -->


                           

                                <div style="margin: 0px 0px 16px;padding: 0px 0px 16px;text-align: right;border-bottom: 1px solid rgba(5, 5, 5, 0.06);">
                                    <span @click="cleanCustomVideo" style="cursor: pointer;color: #4e79fa;">清空选项</span>
                                </div>    

                               
                                <a-upload-dragger
                                    v-model:fileList="fileList"
                                    name="file"
                                    :multiple="false"
                                    action="http://aidpapi.13524.net/index/ajax/upload.html?token=jy"
                                    @change="handleChange"
                                    @drop="handleDrop"
                                >
                                    <p class="ant-upload-drag-icon">
                                        <inbox-outlined></inbox-outlined>
                                    </p>
                                    <p class="ant-upload-text"></p>
                                    <p class="ant-upload-hint">
                                    上传个人视频
                                    </p>
                                </a-upload-dragger>

                                <div class="digital-list-wrap">
                                    <div class="digital-list">
                                        <a-radio-group v-model:value="data.materials.robotId">
                                            
                                            <div v-for="item,i in digitalPrivateList.arr" @click="digitalChange(item.id,item.imgurl_thum)" :key="i" class="digital-item" >
                                                <img :src="item.imgurl_thum" alt="">
                                                <!-- <div class="text">
                                                    <span class="name">{{item.name}}</span>
                                                    <span><FireFilled style="color:#ff660a" />{{item.num}}</span>
                                                </div>
                                                <p>{{item.age}}</p> -->
                                                <a-radio :value="item.id"></a-radio>
                                            </div>
                                        
                                        </a-radio-group>
                                    </div>
                                </div>





<div class="video-content-block">
                                    <img src="" alt="">
                                    <h2>请先上传视频，才能进行混剪合成</h2>
                                    <p> 没有视频素材，可以选择「数字人」无需上传视频素材， 即可生成数字人口播视频</p>
                                    <!-- <a-button>上传视频</a-button> -->
                                    <input type="file" @change="onFileChange" />
                                    <button @click="uploadFile">上传</button>
                                </div>
  


                                

                                <div class="digital-list-wrap" style="margin-top: 0px;">
                                  
                                    <div class="digital-list">

                                        <div class="ai-left-wrap-title" v-if="isShowNum" style="margin: 0px 16px 16px 0px;">
                                            <p><InfoCircleFilled />请勾选至少3条视频才能生成视频，当前已选{{videonumchecked}}条视频。</p>
                                        </div>

                                        <a-radio-group v-model:value="data.materials.bg.resource">
                                            <div v-for="item,i in customVideoList.arr" :key="i" class="digital-item digital-bg-item">
                                                <label :for="item.vid">
                                                    <img :src="item.coverUrl" alt="">
                                                    <p style="position: absolute;bottom: 15px;right: 3px;background: #323335;color: #fff;border-radius: 4px;padding: 0 4px;">{{stoi(item.video_time)}}</p>
                                                </label>
                                                <input
                                                    v-bind:name="item.vid"
                                                    type="checkbox"
                                                    @change="customVideo(item.vid,item.coverUrl,item.videoUrl,item.id)"
                                                    v-model="item.id"
                                                    :checked="item.id"
                                                    style = "position: absolute;right: 5px;top: 5px;"
                                                    :id="item.vid"
                                                />
                                               

                                            </div>
                                        </a-radio-group>
                                    </div>
                                </div>


                                <div class="video-content-block">
                                    <img src="" alt="">
                                    <h2>请先上传视频，才能进行混剪合成</h2>
                                    <p> 没有视频素材，可以选择「数字人」无需上传视频素材， 即可生成数字人口播视频</p>
                                    <!-- <a-button>上传视频</a-button> -->
                                    <button @click="uploadFile">上传视频</button>
                                </div>

                                

                            </div>

                            <!-- 数字人 -->
                            <div  v-if="index==3" class="digital-content">
                                <a-tabs @change="handleTabChange">
                                    <template v-if="data.materials.robotId||data.materials.bg.resource" #rightExtra>
                                        <span @click="cleanDigital" class="clear-btn">清空选项</span>
                                    </template>
                                    <a-tab-pane key="1" tab="公共数字人">
                                        <div class="digital-top-menu">
                                            <a-radio-group v-model:value="search.sex" @change="changDigital">
                                                <a-radio-button value="0">全部</a-radio-button>
                                                <a-radio-button value="2">女性</a-radio-button>
                                                <a-radio-button value="1">男性</a-radio-button>
                                            </a-radio-group>
                                        </div>

                                        <div class="digital-list-wrap">
                                            <!-- <h4>推荐：</h4> -->
                                            <div class="digital-list">
                                                <a-radio-group v-model:value="data.materials.robotId">
                                                    
                                                    <div v-for="item,i in digitalList.arr" @click="digitalChange(item.id,item.imgurl_thum,item.sex,item.name,item.age)" :key="i" class="digital-item" >
                                                        <img :src="item.imgurl_thum" alt="">
                                                        <div class="text">
                                                            <span class="name">{{item.name}}</span>
                                                            <span><FireFilled style="color:#ff660a" />{{item.num}}</span>
                                                        </div>
                                                        <p>{{item.age}}</p>
                                                        <a-radio :value="item.id"></a-radio>
                                                    </div>
                                                
                                                </a-radio-group>
                                            </div>
                                        </div>
                                    </a-tab-pane>
                                    <a-tab-pane key="2" tab="我的数字人">

                                        <a-upload-dragger
                                            v-model:fileList="fileList"
                                            name="file"
                                            :multiple="false"
                                            action="http://aidpapi.13524.net/index/ajax/upload.html?token=jy"
                                            @change="handleChange"
                                            @drop="handleDrop"
                                        >
                                            <p class="ant-upload-drag-icon">
                                                <plus-outlined></plus-outlined>
                                            </p>
                                            <p class="ant-upload-text"></p>
                                            <p class="ant-upload-hint">
                                            上传个人视频
                                            </p>
                                        </a-upload-dragger>

                                        <div class="digital-list-wrap">
                                            <div class="digital-list">
                                                <a-radio-group v-model:value="data.materials.robotId">
                                                    
                                                    <div v-for="item,i in digitalPrivateList.arr" @click="digitalChange(item.id,item.imgurl_thum)" :key="i" class="digital-item" >
                                                        <img :src="item.imgurl_thum" alt="">
                                                        <!-- <div class="text">
                                                            <span class="name">{{item.name}}</span>
                                                            <span><FireFilled style="color:#ff660a" />{{item.num}}</span>
                                                        </div>
                                                        <p>{{item.age}}</p> -->
                                                        <a-radio :value="item.id"></a-radio>
                                                    </div>
                                                
                                                </a-radio-group>
                                            </div>
                                        </div>





                                        <div class="digital-list-wrap">
                                            <div class="digital-list">
                                                <a-radio-group v-model:value="data.materials.bg.resource">
                                                    
                                                    <div v-for="item,i in bgList.arr" @click="bgChange(item.id,item.imgurl_thum)" :key="i" class="digital-item digital-bg-item" >
                                                        <img :src="item.imgurl_thum" alt="">
                                                        <a-radio :value="item.id"></a-radio>
                                                    </div>
                                                </a-radio-group>
                                            </div>
                                        </div>

                                    </a-tab-pane>
                                </a-tabs>
                            </div>



                            <!-- 配音 -->
                            <div  v-if="index==4" class="video-content">
                                <audio ref="audioPlayer" style="display:none" autoplay controls :src="testVideo"></audio>
                                <div class="video-top-menu">
                                    <a-radio-group v-model:value="search.sex" @change="changSex">
                                        <a-radio-button value="0">全部</a-radio-button>
                                        <a-radio-button value="2">女性</a-radio-button>
                                        <a-radio-button value="1">男性</a-radio-button>
                                    </a-radio-group>
                                    <!-- <a-row class="video-speed" :gutter="8">
                                        <a-col :span="4" >语速</a-col>
                                        <a-col :span="18"><a-slider id="test" :min="0.5" :max="2" :step="0.1" v-model:value="data.audioOption.voiceSpeed" :disabled="disabled" /></a-col>
                                        <a-col :span="2">{{data.audioOption.voiceSpeed}}</a-col>
                                    </a-row> -->
                                </div>

                                <div class="video-list-wrap">
                                    <div class="video-list">
                                        <a-radio-group v-model:value="data.materials.voiceId">
                                            
                                            <div class="video-item"  v-for="item,i in videoList.arr" :key="i" @click="videoChange(item.id,item.desc,item.url)">
                                                <div class="video-icon" >
                                                    <a-avatar v-if="item.type==2" :size="50" style="background-color: #ffebeb">
                                                        <template #icon><WomanOutlined style="color:#ffbaba" :rotate="45" /></template>
                                                    </a-avatar>
                                                    <a-avatar v-if="item.type==1" :size="50" style="background-color: #e3eaff">
                                                        <template #icon><ManOutlined style="color:#a7bafa" /></template>
                                                    </a-avatar>
                                                    <!-- <div class="voice-play-wrap">
                                                        
                                                    </div> -->
                                                </div>
                                                <div class="text">
                                                    <span class="name">{{item.title}}</span>
                                                    <!-- <span v-if="item.type==2">女声</span><span v-if="item.type==1">女声</span>- -->
                                                    <span>{{item.desc}}</span>
                                                </div>
                                                <a-radio :value="item.id"></a-radio>

                                                
                                            </div>

                                        </a-radio-group>
                                    </div>
                                </div>
                                  
                            </div>

                            

                            <!-- 音乐 -->
                            <div  v-if="index==5" class="music-content">
                                <div class="music-top-menu">
                                    <a-input class="music-search" v-model:value="search.music" placeholder="请输入歌曲名称">
                                    <template #suffix>
                                        <a-tooltip title="Extra information">
                                            <SearchOutlined style="color: rgba(0, 0, 0, 0.45)" />
                                        </a-tooltip>
                                    </template>
                                    </a-input>
                                    <a-row class="music-speed" :gutter="8">
                                        <a-col :span="4" >音量</a-col>
                                        <a-col :span="18"><a-slider id="test" :min="0.5" :max="2" :step="0.1" v-model:value="data.audioOption.voiceSpeed" :disabled="disabled" /></a-col>
                                        <a-col :span="2">{{data.audioOption.voiceSpeed}}</a-col>
                                    </a-row>
                                </div>

                                <div class="music-list-wrap">
                                    <h4>推荐：</h4>
                                    <div class="music-list">
                                        <a-radio-group v-model:value="data.materials.robotId">
                                            
                                            <div class="music-item" >
                                                <img src="./../../assets/music1.jpeg" alt="">
                                                <div class="text">
                                                    <span class="name">A supple piano jingle that feels Japanese summer(845035)</span>
                                                    <span>Pi坊</span>
                                                    <div><span>00:20</span><span>其他</span></div>
                                                </div>
                                                
                                                <a-radio ::value="1"></a-radio>
                                            </div>

                                        </a-radio-group>
                                    </div>
                                </div>
                            </div>

                            <!-- 字幕 -->
                            <div  v-if="index==6" class="font-content">
                                <a-tabs>
                                    <template v-if="data.wordArtStyle.name" #rightExtra>
                                        <span @click="cleanFont" class="clear-btn">清空选项</span>
                                    </template>
                                    <a-tab-pane key="3" tab="字体">
                                        <div class="font-top-menu">
                                            <h4>字号：</h4>
                                            <a-input-number  id="inputNumber" v-model:value="formData.fontsize" :min="12" :max="32" />
                                            <h4>对齐：</h4>
                                            <a-radio-group v-model:value="search.fontStyle" @change="fontStyleChange">
                                                <a-radio-button value="left"><AlignLeftOutlined />左对齐</a-radio-button>
                                                <a-radio-button value="center"><AlignCenterOutlined />居中</a-radio-button>
                                                <a-radio-button value="right"><AlignRightOutlined />右对齐</a-radio-button>
                                            </a-radio-group>
                                        </div>
                                        <div class="font-list-wrap">
                                            <h4>类型：</h4>
                                            <div class="font-list">
                                                <a-radio-group v-model:value="data.wordArtStyle.name" >
                                                    
                                                    <div v-for="item,i in fontList" :key="i" class="font-item" @click="fontChange(item.value,item.text,item.url)">
                                                        <h2 :style="{fontFamily:item.value}">{{item.text}} </h2>
                                                        <a-radio :value="item.value"></a-radio>
                                                    </div>
                                                </a-radio-group>
                                                
                                            </div>
                                        </div>
                                    </a-tab-pane>
                                    <!-- <a-tab-pane key="4" tab="样式">
                                        <div class="font-top-menu">
                                            <h4>字号：</h4>
                                            <a-input-number id="inputNumber" v-model:value="formData.fontsize" :min="12" :max="32" />
                                            <h4>对齐：</h4>
                                            <a-radio-group v-model:value="search.fontStyle">
                                                <a-radio-button value="1"><AlignLeftOutlined />左对齐</a-radio-button>
                                                <a-radio-button value="2"><AlignCenterOutlined />居中</a-radio-button>
                                                <a-radio-button value="3"><AlignRightOutlined />右对齐</a-radio-button>
                                            </a-radio-group>
                                        </div>
                                        <div class="font-list-wrap">
                                            <h4>类型：</h4>
                                            <div class="font-list">
                                                <a-radio-group v-model:value="data.materials.robotId">
                                                    
                                                    <div class="font-item" >
                                                        <img src="./../../assets/font1.jpg" alt="">
                                                        <a-radio ::value="1"></a-radio>
                                                    </div>

                                                </a-radio-group>
                                            </div>
                                        </div>
                                    </a-tab-pane> -->
                                </a-tabs>
                               
                                
                            </div>


                        </div>

                        <div class="aicreat-button-wrap">
                            
                            <a-button type="primary" :loading="loading" @click="$noMulClicks(creatVideo)">
                                <template #icon><SendOutlined /></template>
                                生成视频
                            </a-button>
                        </div>
                    </a-flex>
                    <a-card class="aicreat-wrap ai-right-wrap" :bordered="false">
                        <template #cover>
                            <div class="ai-right-wrap-title">
                                <h2>视频画面</h2>
                                <p><InfoCircleFilled />当前画面为素材预览，非最终成片效果；您对AI生成内容应严格遵循国家相关法律法规的规定。</p>
                            </div>
                        </template>
                        
                        <div class="ai-canvas-content">
                            <div class="ai-canvans-wrap">
                                <div class="ai-canvans">

                                    
                                    <!-- <div class="ai-canvans-img" v-if="data.materials.robotId">
                                        <img class="bg-img" :src="formData.bg_image_url" alt="">
                                        
                                        <VueDragResizeRotate v-if="isFont"  :lock-aspect-ratio="true" :resizable="false" w="230" h="88" :x="0" :y="320" :parent="true" :z="12" >
                                           <div class="font-img" :style="{ textAlign: search.fontStyle, fontSize: formData.fontsize + 'px' ,fontFamily:fontStyle}">
                                            {{ data.wordArtStyle.cname}}
                                           </div>
                                        </VueDragResizeRotate>
                                        <VueDragResizeRotate v-if="data.materials.robotId" class-name="my-class" @resizing="onResizeStop" @dragstop="onDragStop"  :lock-aspect-ratio="true" :handles="['tl','tr','bl','br']" w="92" h="163" :x="80" :y="246" :parent="true" :z="9" :min-width="90" :min-height="163" :max-width="232"  :max-height="412">
                                           <img class="digital-img" :src="formData.digital" alt="">
                                        </VueDragResizeRotate>

                                        
                                    </div>

                                    <div class="ai-config-item video-config" v-else-if="videonumchecked>=1">
                                         <div class="ai-canvans-img" v-for="item,i in customVideoValueCheckedAiAndCus" :key="i" >
                                            <img class="bg-img" :src="item.videoInfo.coverUrl" alt="">
                                        </div>
                                    </div> -->


                                    <div class="ai-canvans-img" >
                                        <img class="bg-img" v-if="formDataZdy.bg_image_url" :src="formDataZdy.bg_image_url" alt="">

                                        <!-- <img class="bg-img" v-if="videonumchecked>=1" :src="customVideoValueCheckedAiAndCus[0].videoInfo.coverUrl" alt=""> -->

                                        <!-- <VueDragResizeRotate v-if="isFont"  :lock-aspect-ratio="true" :resizable="false" w="230" h="88" :x="0" :y="320" :parent="true" :z="12" >
                                           <div class="font-img" :style="{ textAlign: search.fontStyle, fontSize: formData.fontsize + 'px' ,fontFamily:fontStyle}">
                                            {{ data.wordArtStyle.cname}}
                                           </div>
                                        </VueDragResizeRotate> -->
                                        <VueDragResizeRotate v-if="formDataZdy.digital" class-name="my-class" @resizing="onResizeStop" @dragstop="onDragStop"  :lock-aspect-ratio="true" :handles="['tl','tr','bl','br']" w="232" h="411" :x="1" :y="0" :parent="true" :z="9" :min-width="90" :min-height="163" :max-width="232"  :max-height="412">
                                           <img class="digital-img" :src="formDataZdy.digital" alt="">
                                        </VueDragResizeRotate>
                                    </div>


                                    <!-- <div class="ai-canvans-img" v-if="data.materials.robotId">
                                        <img class="bg-img" :src="formData.bg_image_url" alt="">
                                        <VueDragResizeRotate v-if="isFont"  :lock-aspect-ratio="true" :resizable="false" w="230" h="88" :x="0" :y="320" :parent="true" :z="12" >
                                           <div class="font-img" :style="{ textAlign: search.fontStyle, fontSize: formData.fontsize + 'px' ,fontFamily:fontStyle}">
                                            {{ data.wordArtStyle.cname}}
                                           </div>
                                        </VueDragResizeRotate>
                                        <VueDragResizeRotate v-if="data.materials.robotId" class-name="my-class" @resizing="onResizeStop" @dragstop="onDragStop"  :lock-aspect-ratio="true" :handles="['tl','tr','bl','br']" w="92" h="163" :x="80" :y="246" :parent="true" :z="9" :min-width="90" :min-height="163" :max-width="232"  :max-height="412">
                                           <img class="digital-img" :src="formData.digital" alt="">
                                        </VueDragResizeRotate>
                                    </div>

                                    <div class="ai-config-item video-config" v-if="videonumchecked>=1&&!data.materials.robotId">
                                         <div class="ai-canvans-img" v-for="item,i in customVideoValueCheckedAiAndCus" :key="i" >
                                            <img class="bg-img" :src="item.videoInfo.coverUrl" alt="">
                                            <VueDragResizeRotate v-if="isFont"  :lock-aspect-ratio="true" :resizable="false" w="230" h="88" :x="0" :y="320" :parent="true" :z="12" >
                                                <div class="font-img" :style="{ textAlign: search.fontStyle, fontSize: formData.fontsize + 'px' ,fontFamily:fontStyle}">
                                                    {{ data.wordArtStyle.cname}}
                                                </div>
                                            </VueDragResizeRotate>
                                        </div>
                                    </div> -->






                                </div>


                                <div class="ai-config-item digital-config video-config video-config-bottom" v-if="videonumchecked>=1||data.materials.robotId">

                                    <!-- <div class="ai-config-item digital-config" v-if="data.materials.robotId"> -->
                                       
                                        
                                    <!-- </div> -->



                                    <div class="video-config-item" style="margin: 8px 0px 8px 0px;">

                                        <span style="display: inline-flex;" v-if="data.materials.robotId" @click="videobottom(1,data.materials.robotId)">
                                            <!-- <img class="digital-config1-1" :src="formData.bg_image_url" alt=""> -->
                                            <img class="digital-config2-2" :src="formData.digital" alt="">
                                        </span>
                                        <span v-if="videonumchecked>0">
                                            <span v-for="item,i in customVideoValueChecked.slice(0, 18)" :key="i" @click="videobottom(2,item.videoInfo.coverUrl)">
                                                <img class="video-config1" :src="item.videoInfo.coverUrl" alt="">
                                            </span>
                                        </span>
                                        <!-- <span v-if="videonumchecked<3" class="zsgx3g">至少勾选3个视频</span> -->
                                        <span v-if="videonumchecked>18"><img class="video-config1" src="./../../assets/3d.png" alt=""></span>
                                        
                                    </div>
                                </div>

                                <!-- <div class="ai-canvans-img2">
                                    <img class="bg-img" :src="formData.bg_image_url" alt="" style="width: 36px;height: 36px;">
                                </div> -->
                               
                            </div>
                            <div class="ai-canvans-config">
                                <h2>当前配置</h2>
                                <a-tooltip title="prompt text" color="#ffffff">
                                    <div class="ai-config-item font-config">
                                        脚本：自定义-{{data.materials.script.scriptContent.length}}字
                                    </div>
                                </a-tooltip>

                                <div class="ai-config-item video-config" v-if="videonumchecked>=1">
                                    <div>
                                        <span>视频：</span>
                                        <span class="vedionum">{{videonumchecked}}/20</span>
                                    </div>
                                    <div class="video-config-item">

                                        <span v-for="item,i in customVideoValueChecked.slice(0, 4)" :key="i" >
                                             <img class="video-config1" :src="item.videoInfo.coverUrl" alt="">
                                        </span>
                                        <span v-if="videonumchecked<3" class="zsgx3g">至少勾选3个视频</span>
                                        <span v-if="videonumchecked>4"><img class="video-config1" src="./../../assets/3d.png" alt=""></span>
                                        
                                    </div>
                                </div>

                                <div class="ai-config-item digital-config" v-if="data.materials.robotId">
                                    <div>
                                        数字人：
                                    </div>
                                    <div class="digital-config-item">
                                        <!-- <img class="digital-config1" :src="formData.bg_image_url" alt=""> -->
                                        <img class="digital-config2" :src="formData.digital" alt="" style="position: inherit;">
                                         {{digitalNametxt}}-<span v-if="digitalSextxt==1">男</span><span v-if="digitalSextxt==2">女</span>-{{digitalAgetxt}}
                                    </div>
                                </div>
                                <a-tooltip title="prompt text" color="#ffffff" v-if="data.materials.voiceId">
                                    <div class="ai-config-item font-config">
                                        配音：{{data.audioOption.voiceSpeed}}X-{{videotxt}}
                                    </div>
                                </a-tooltip>

                                <!-- <a-tooltip title="prompt text" color="#ffffff" v-if="data.wordArtStyle.name">
                                    <div class="ai-config-item font-config">
                                        字幕：{{formData.fontsize}}px-{{fontAligntxt}}-{{data.wordArtStyle.cname}}
                                    </div>
                                </a-tooltip> -->

                                
                            </div>
                        </div>
                    </a-card>
                    <!-- <div class="aicreat-wrap ai-right-wrap">

                    </div> -->
                </a-flex>
            </a-flex>
        </div>


            <!-- 任务列表 -->
        <a-drawer
            v-model:open="taskOpen"
            title="任务列表"
            rootClassName="taskDrawer"
        >
            <template #extra>
                <a-button type="link" :size="size" @click="getList"><RedoOutlined />刷新</a-button>
            </template>
            <div class="task-list">
                <div class="task-item" v-for="item,i in taskList.arr" :key="i">
                    <div class="left-task-info">
                        <div class="task-img" @click="vivwerVide(item.videoUrl_file)" @mouseenter="handleMouseEnter(i)" @mouseleave="handleMouseLeave(i)">
                            <img v-if="item.coverUrl_file" :src="item.coverUrl_file" alt="">
                            <img v-else src="./../../assets/block.png" alt="">
                            <div class="video-mask" v-if="ismask==i">
                                <EyeOutlined />预览
                            </div>
                        </div>
                        <div @click="openVideo(item.videoUrl_file)">
                            <h4>{{item.title}}</h4>
                            <p>{{item.create_time}}</p>
                        </div>
                    </div>
                    <div class="right-task-tool">
                        <p v-if="item.video_status===0"><ClockCircleOutlined  style="color:#00943e" /> 生成中</p>
                        <p v-if="item.video_status===1"><CheckCircleFilled  style="color:#00943e" /> 生成成功</p>
                        <p v-if="item.video_status===1" @click="downUrl(item.videoUrl_file,item.title)" class="down-btn"><DownCircleFilled style="color:#4e79fa" /> 视频下载</p>
                    </div>
                </div>
            </div>

            <a-pagination
            style="margin-top:16px"
                v-model:current="page.page"
                v-model:page-size="page.limit"
                :page-size-options="pageSizeOptions"
                :total="page.total"
                showTotal
                show-size-changer
                @showSizeChange="onShowSizeChange"
            >
            </a-pagination>
        </a-drawer>
       


        <!-- AI脚本 -->
         <a-drawer
            size="large"
            v-model:open="aitextOpen"
            title="脚本生成"
            rootClassName="aiDrawer"
        >
        <template #footer>
            <a-button @click="closeDrawer" style="margin-right: 8px" >取消</a-button>
            <a-button @click="confrimAitext"  type="primary" >确定</a-button>
        </template>
            <div class="aitext-content">
                <div class="aitext-wrap aitext-wrap-left">
                    <a-tabs type="card" v-model:activeKey="aiActive">
                        <a-tab-pane key="1" tab="通用电商">
                            <div class="aitext-form">
                                <a-form layout="Vertical" >
                                    <a-form-item label="产品信息">
                                        <a-input v-model:value="formState1.introduction" placeholder="请输入产品信息" />
                                    </a-form-item>
                                    <a-form-item label="推广场景" >
                                        <a-radio-group v-model:value="formState1.promotion_scene">
                                            <a-radio-button value="短视频带货">短视频带货</a-radio-button>
                                            <a-radio-button value="引流直播间">引流直播间</a-radio-button>
                                        </a-radio-group>
                                    </a-form-item>
                                    <a-form-item label="产品卖点">
                                        <a-textarea show-count :maxlength="40" v-model:value="formState1.sell_point" placeholder="请输入产品卖点，如“饱和度高；不易脱妆”，回车或输入分号 ；后生成卖点标签，结构化、分段表达卖点效果更佳" :rows="4" />
                                    </a-form-item>
                                    <a-form-item label="脚本风格">
                                        <a-radio-group v-model:value="formState1.script_style">
                                            <a-radio-button value="不限">不限</a-radio-button>
                                            <a-radio-button value="国货">国货</a-radio-button>
                                        </a-radio-group>
                                    </a-form-item>
                                    <a-form-item label="优惠活动">
                                        <a-input v-model:value="formState1.preferential" show-count :maxlength="22"  placeholder="请输入价格/优惠活动，如“拍一发三；99元三件”等" />
                                    </a-form-item>
                                    <a-form-item label="适用人群">
                                        <a-input v-model:value="formState1.suit_crowd" show-count :maxlength="20"  placeholder="请输入产品的适用人群，如“宝妈；小姐姐”" />
                                    </a-form-item>
                                        <a-form-item label="用户痛点">
                                        <a-input v-model:value="formState1.user_pain_spot" show-count :maxlength="22"  placeholder="请输入商品解决的痛点，例如“没气色；价格昂贵”" />
                                    </a-form-item>
                                    <a-form-item label="适用场景">
                                        <a-input v-model:value="formState1.suit_scene" show-count :maxlength="22"  placeholder="请输入商品的适用场景，例如“约会”" />
                                    </a-form-item>
                                    <a-form-item label="脚本字数">
                                        <a-select v-model:value="formState1.script_word_count" >
                                            <a-select-option value="不限字数">不限字数</a-select-option>
                                            <a-select-option value="50~75字">50~75字（≈10~15s正常口播时长）</a-select-option>
                                            <a-select-option value="75~150字">75~150字（≈15~30s正常口播时长）</a-select-option>
                                            <a-select-option value="150~300">150~300字（≈30~60s正常口播时长）</a-select-option>
                                            <a-select-option value="≥300字">≥300字（≥60s正常口播时长）</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    <a-form-item>
                                        <a-button @click="creatText('通用电商')" type="primary">立即生成</a-button>
                                    </a-form-item>
                                </a-form>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="大健康">
                            <div class="aitext-form">
                                <a-form layout="Vertical" >
                                    <a-form-item label="产品信息">
                                        <a-input v-model:value="formState2.introduction" placeholder="请输入产品信息" />
                                    </a-form-item>
                                    <a-form-item label="产品功效">
                                        <a-textarea show-count :maxlength="40" v-model:value="formState2.effect" placeholder="请输入产品功效，例如“改善肝损伤”" :rows="4" />
                                    </a-form-item>
                                    <a-form-item label="用户痛点">
                                        <a-input v-model:value="formState2.user_pain_spot" show-count :maxlength="22"  placeholder="请输入产品解决的用户痛点，例如“肚子大；眼睛干涩”" />
                                    </a-form-item>
                                    <a-form-item label="产品卖点">
                                        <a-input v-model:value="formState2.sell_point" show-count :maxlength="22"  placeholder="请输入产品卖点，如“古法工艺；富含总黄酮”" />
                                    </a-form-item>

                                    <a-form-item label="适用人群">
                                        <a-input v-model:value="formState2.suit_crowd" show-count :maxlength="20"  placeholder="请输入商品的适用人群，如“经常用眼的孩子”等" />
                                    </a-form-item>
                                    
                                    <a-form-item label="适用场景">
                                        <a-input v-model:value="formState2.suit_scene" show-count :maxlength="22"  placeholder="请输入商品的适用场景/方法，例如“睡前；早晚各一次”" />
                                    </a-form-item>
                                    <a-form-item label="优惠活动">
                                        <a-input v-model:value="formState2.preferential" show-count :maxlength="22"  placeholder="请输入商品价格或优惠活动，例如“99元三件”" />
                                    </a-form-item>
                                    <a-form-item label="脚本字数">
                                        <a-select v-model:value="formState2.script_word_count" >
                                            <a-select-option value="不限字数">不限字数</a-select-option>
                                            <a-select-option value="50~75字">50~75字（≈10~15s正常口播时长）</a-select-option>
                                            <a-select-option value="75~150字">75~150字（≈15~30s正常口播时长）</a-select-option>
                                            <a-select-option value="150~300">150~300字（≈30~60s正常口播时长）</a-select-option>
                                            <a-select-option value="≥300字">≥300字（≥60s正常口播时长）</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    <a-form-item>
                                        <a-button @click="creatText('大健康')" type="primary">立即生成</a-button>
                                    </a-form-item>
                                </a-form>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="3" tab="工具软件">
                            <div class="aitext-form">
                                <a-form layout="Vertical" >
                                    <a-form-item label="产品名称">
                                        <a-input v-model:value="formState3.title" placeholder="请输入产品名称" show-count :maxlength="20" />
                                    </a-form-item>
                                    <a-form-item label="产品卖点">
                                        <a-textarea show-count :maxlength="40" v-model:value="formState3.sell_point" placeholder="请输入产品功能或卖点，例如“智能语音播报；查看未来15天天气”" :rows="4" />
                                    </a-form-item>
                        
                                    <a-form-item label="用户痛点">
                                        <a-input v-model:value="formState3.user_pain_spot" show-count :maxlength="22"  placeholder="请输入用户痛点，例如“出门被雨淋”" />
                                    </a-form-item>
                                    
                                    <a-form-item label="适用场景">
                                        <a-input v-model:value="formState3.suit_scene" show-count :maxlength="22"  placeholder="请输入产品的适用场景，例如“暴风雨；台风”" />
                                    </a-form-item>
                                    <a-form-item label="脚本字数">
                                        <a-select v-model:value="formState3.script_word_count" >
                                            <a-select-option value="不限字数">不限字数</a-select-option>
                                            <a-select-option value="50~75字">50~75字（≈10~15s正常口播时长）</a-select-option>
                                            <a-select-option value="75~150字">75~150字（≈15~30s正常口播时长）</a-select-option>
                                            <a-select-option value="150~300">150~300字（≈30~60s正常口播时长）</a-select-option>
                                            <a-select-option value="≥300字">≥300字（≥60s正常口播时长）</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    <a-form-item>
                                        <a-button @click="creatText('工具软件')" type="primary">立即生成</a-button>
                                    </a-form-item>
                                </a-form>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="4" tab="金融">
                            <div class="aitext-form">
                                <a-form layout="Vertical" >
                                    <a-form-item label="产品名称">
                                        <a-input v-model:value="formState4.title" placeholder="请输入产品名称" show-count :maxlength="20" />
                                    </a-form-item>
                                    <a-form-item label="产品卖点">
                                        <a-textarea show-count :maxlength="40" v-model:value="formState4.sell_point" placeholder="请输入产品卖点，如“最高600万保额”，回车或输入分号；后生成卖点标签，结构化、分段表达卖点效果更佳" :rows="4" />
                                    </a-form-item>

                                    <a-form-item label="适用人群">
                                        <a-input v-model:value="formState4.suit_crowd" show-count :maxlength="20"  placeholder="请输入需要在脚本中强调适用人群。如“有车的人”等" />
                                    </a-form-item>
                        
                                    <a-form-item label="用户痛点">
                                        <a-input v-model:value="formState4.user_pain_spot" show-count :maxlength="22"  placeholder="请输入商品解决的痛点，例如“担心生活出现意外”" />
                                    </a-form-item>
                                    
                                    
                                    <a-form-item label="适用场景">
                                        <a-input v-model:value="formState4.suit_scene" show-count :maxlength="22"  placeholder="请输入商品适用场景，例如“过年送家长礼物”" />
                                    </a-form-item>
                                    <a-form-item label="优惠活动">
                                        <a-input v-model:value="formState4.preferential" show-count :maxlength="22"  placeholder="请输入商品价格或优惠活动，例如“每月1元起”" />
                                    </a-form-item>
                                    <a-form-item label="脚本字数">
                                        <a-select v-model:value="formState4.script_word_count" >
                                            <a-select-option value="不限字数">不限字数</a-select-option>
                                            <a-select-option value="50~75字">50~75字（≈10~15s正常口播时长）</a-select-option>
                                            <a-select-option value="75~150字">75~150字（≈15~30s正常口播时长）</a-select-option>
                                            <a-select-option value="150~300">150~300字（≈30~60s正常口播时长）</a-select-option>
                                            <a-select-option value="≥300字">≥300字（≥60s正常口播时长）</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    <a-form-item>
                                        <a-button @click="creatText('金融')" type="primary">立即生成</a-button>
                                    </a-form-item>
                                </a-form>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="5" tab="教育">
                            <div class="aitext-form">
                                <a-form layout="Vertical" >
                                    <a-form-item label="产品信息">
                                        <a-input v-model:value="formState5.introduction" placeholder="请输入产品信息" />
                                    </a-form-item>
                                    <a-form-item label="产品卖点">
                                        <a-textarea show-count :maxlength="40" v-model:value="formState5.sell_point" placeholder="请输入产品卖点，如“全国通用”，回车或输入分号 ；后生成卖点标签，结构化、分段表达卖点效果更佳" :rows="4" />
                                    </a-form-item>

                                    <a-form-item label="适用人群">
                                        <a-input v-model:value="formState5.suit_crowd" show-count :maxlength="20"  placeholder="请输入需要在脚本中强调适用人群。如“初中学校”等" />
                                    </a-form-item>
                        
                                    <a-form-item label="用户痛点">
                                        <a-input v-model:value="formState5.user_pain_spot" show-count :maxlength="22"  placeholder="请输入商品解决的痛点，例如“数学学不懂”" />
                                    </a-form-item>
                                    
                                    
                                    <a-form-item label="适用场景">
                                        <a-input v-model:value="formState5.suit_scene" show-count :maxlength="22"  placeholder="请输入商品适用场景，例如“寒暑假”" />
                                    </a-form-item>
                                    <a-form-item label="优惠活动">
                                        <a-input v-model:value="formState5.preferential" show-count :maxlength="22"  placeholder="请输入商品价格或优惠活动，例如“不花钱试听一节”" />
                                    </a-form-item>
                                    <a-form-item label="脚本字数">
                                        <a-select v-model:value="formState5.script_word_count" >
                                            <a-select-option value="不限字数">不限字数</a-select-option>
                                            <a-select-option value="50~75字">50~75字（≈10~15s正常口播时长）</a-select-option>
                                            <a-select-option value="75~150字">75~150字（≈15~30s正常口播时长）</a-select-option>
                                            <a-select-option value="150~300">150~300字（≈30~60s正常口播时长）</a-select-option>
                                            <a-select-option value="≥300字">≥300字（≥60s正常口播时长）</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    <a-form-item>
                                        <a-button @click="creatText('教育')" type="primary">立即生成</a-button>
                                    </a-form-item>
                                </a-form>
                            </div>
                        </a-tab-pane>
                    </a-tabs>
                    
                </div>
                <div class="aitext-wrap">
                    <div class="ai-right-wrap-title" style="padding:0">
                        <h2>为你生成</h2>
                        <p><InfoCircleFilled />内容为AI生成，仅供参考。</p>
                    </div>

                    <a-card title="脚本文案_20231227" style="margin-top: 30px" v-if="aitext">
                        <a-textarea v-if="isedit" show-count :maxlength="1000" v-model:value="aitext" placeholder="脚本内容" :rows="4" />

                        <div v-if="isedit==false">{{aitext}}</div> 

                        <template #actions >
                            <span v-if="isedit==false" style="color:#1777ff"  @click="openedit"><edit-outlined key="edit" /> 编辑</span>
                            <span v-if="isedit==false" @click="saveAitext(aiid)"><SaveOutlined key="edit" /> 保存</span>
                            
                            <a-button v-if="isedit" type="primary" size="small">确定</a-button>
                            <a-button v-if="isedit" @click="closeedit" size="small">取消</a-button>
                            <!-- <span v-if="isedit"   style="color:#1777ff">确定</span>
                            <span v-if="isedit" @click="closeedit">取消</span> -->
                        </template>

                    </a-card>
                    <a-spin style="margin:60px auto;width:100%" v-if="isloading" />
                </div>
            </div>
        </a-drawer>

        <!-- 脚本列表 -->
         <a-drawer
            size="large"
            v-model:open="aiListOpen"
            title="脚本生成"
            rootClassName="aiDrawer"
        >
            <template #footer>
                <a-button @click="closeDrawer" style="margin-right: 8px" >取消</a-button>
                <a-button @click="selectAilist" type="primary" >确定</a-button>
            </template>
    
            <div class="aitext-wrap ailist-wrap" style="width:100%">
                    <div class="ai-right-wrap-title" style="padding:0">
                        <h2>为你生成</h2>
                        <p><InfoCircleFilled />内容为AI生成，仅供参考。</p>
                    </div>

                    <a-radio-group v-model:value="selectAItext">
                        <a-card :title="'脚本文案'+item.update_time" style="margin-top: 30px" v-for="item in aiList.arr" :key="item.id">
                            <a-textarea v-if="isedit" show-count :maxlength="1000" v-model:value="value1" placeholder="脚本内容" :rows="4" />

                            <div v-if="isedit==false">{{item.content}}</div> 

                            <template #actions >
                                <a-radio style="float:left;margin-left: 15px;" :value="item.content">选择脚本</a-radio>
                               <span style="float:right;margin-right: 15px;">创作者：AIBOT</span>
                            </template>

                            

                        </a-card>
                    </a-radio-group>

                    <a-pagination
                    style="margin-top:16px"
                        v-model:current="page2.page"
                        v-model:page-size="page2.limit"
                        :page-size-options="pageSizeOptions"
                        :total="page2.total"
                        show-size-changer
                        @showSizeChange="onShowSizeChange2"
                    >
                    </a-pagination>
            </div>
         </a-drawer>

        <!-- 视频预览 -->
        <div class="vivwer-mask" @click="closeVideo" v-if="vivwerurl"></div>
        <video v-if="vivwerurl" class="video-wrap" :src="vivwerurl" controls></video>


        <!-- 会员 -->
         <a-modal v-model:visible="mvisible" title="" :footer="null" class="member-content" style="width:50%;">
            <div class="member-wrap" >
              <div class="member-wrap-title">
                <h2>开通会员，获取AI数字人生成权限</h2>
                <p>无限生成，不限次数</p>
              </div>
              <a-row justify="space-between" :gutter="24" class="member-top" >
                <a-col :xs="24" :sm="24" :md="9" :lg="9" :xl="8" v-for="item in tcList.data" :key="item.id">
                  <div :class="currentType==item.id?'activeType member-item':'member-item'" @click="selectType(item.id,item.total_price)">
                    <h3>{{item.title}}</h3>
                    <div class="price">
                      <i>￥</i>
                      <span>{{item.total_price}}</span>
                    </div>
                    <p class="price-desc">{{item.describes}}</p>
                  </div>
                </a-col>
              </a-row>

              <a-row justify="space-between" align="middle" :gutter="24" class="member-bottom" >
                <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <div class="member-desc">
                    <ul>
                      <li>更快的响应速度</li>
                      <li>优先使用新功能</li>
                      <!-- <li>回答问题更智能</li>
                      <li>可以多轮对话</li> -->
                    </ul>
                    <a-checkbox v-model:checked="checked"> 
                        开通会员代表您接受 <a target="_blank" href="https://chat.it81.com/agreement.html">《订阅会员协议》</a>
                    </a-checkbox>
                  </div>
                </a-col>
                <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <div class="price-code-wrap">
                    <div class="price-text">
                      <p>应付金额：<span>￥{{currenPay}}</span></p>
                      <!-- <label>截止日期：{{currentTime}}</label> -->
                    </div>

                    <div class="price-code" v-if="iscode">
                      <img class="code-img" :src="orderInfo.data.pay_QR_code" alt="">
                      <p><WechatOutlined /><AlipayCircleOutlined />支付宝/微信扫码支付</p>
                    </div>
                    <div v-if="iscode==false" style="width:100%">
                      <a class="payBtn" :href="orderInfo.data.href_pay">立即支付</a>
                    </div>
                  </div>
                </a-col>
              </a-row>

              
            </div>
          
        </a-modal>

        </a-config-provider>

    </div>
</template>

<style>
    :where(.css-dev-only-do-not-override-16pw25h).ant-upload-wrapper .ant-upload-drag {height:130px}
    :where(.css-16pw25h).ant-upload-wrapper .ant-upload-drag {height:130px}
    .digital-config2-2 {
        -o-object-fit: contain;
        object-fit: contain;
        width: 33px;
        height: 48px;
        position: absolute;
        top: 8px;
    }
</style>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';

import { reactive, ref } from '@vue/reactivity'
import { getCurrentInstance,computed,toRefs } from 'vue'
import { message,Modal} from 'ant-design-vue';

import moment from "moment";
import { formatDate } from "../../utils/filter.js";
import {useRouter} from "vue-router";
import { saveAs } from 'file-saver';









export default {
    data () {
        return {
            locale: zhCN,
            noClick: true
        }
    },
    setup(){

        //数字人类型 1-公共 2-个人
        let aiIndex = ref();
        aiIndex = 1;
        let handleTabChange=function(activeKey){
            aiIndex = activeKey;
            //清空数字人
            cleanDigital();
            // console.log('当前激活的标签页索引:', activeKey);
        }


        



        // 过滤器
        const changeFormdate = computed(() => {
            return (time) => {
                let date = new Date(time);
                return formatDate(date);
            };
        });
        let index=ref(1)
        let activeKey=ref(1)
        const { proxy } = getCurrentInstance()
        let search=reactive({sex:"0",music:"",fontStyle:"center",video:""})
        // let formData=reactive({text:"",digital:"",digitalbg:"",font:"",video:"",speed:"1.0",fontsize:"20",bg_image_url: require('@/assets/bg1.png'),digital_image_url:""})
        let formData=reactive({text:"",digital:"",digitalbg:"",font:"",video:"",speed:"1.0",fontsize:"20",bg_image_url: "",digital_image_url:""})
        let formDataZdy=reactive({});
        let data=reactive({
            materials: {title: "",
                script: {scriptType: 1,scriptContent: "" },
                robotId: "", 
                bg: {resource: "icc/smartClip/background/v2.png",materialType: 1},
                voiceId: ""
            },
            wordArtStyle: {
                "wordArtId": "10",
                "wordArtName": "白底黑边",
                "fontSize": 62.06896551724138,
                "alignType": 1,
                "pos": {
                "posY": 958
                },
                "wordArtParam": {
                "u_inner1Color_1": "#FFFBFBff",
                "u_inner1Color_2": "#FFFBFBff",
                "u_inner1Color_3": "#FFFBFBff",
                "u_inner1OpacityFillLinear": [
                    1,
                    1,
                    1
                ],
                "u_outLine1Color_1": "#000000ff",
                "u_outLine1Color_2": "#000000ff",
                "u_outLine1Color_3": "#000000ff",
                "u_outLine1lineWidth": 5,
                "u_outLine1OpacityFillLinear": [
                    1,
                    1,
                    1
                ],
                "u_shadow1Color_1": "#000000ff",
                "u_shadow1Color_2": "#000000ff",
                "u_shadow1Color_3": "#000000ff",
                "u_shadow1offset": [
                    0,
                    0
                ],
                "u_shadow1lineWidth": 0,
                "u_shadow1outline1Color_1": "#000000ff",
                "u_shadow1outline1lineWidth": 0,
                "u_shadow1blurSize": 0,
                "u_shadow1OpacityFillLinear": [
                    1,
                    1,
                    1
                ]
                },
                cname: "",
                name: "",
                fontUri: ""
            },
            audioOption:{
                voiceSpeed:1,
                voiceVolume:1
            },
            renderOption: {targetWidth: 720,targetHeight: 1280,targetVideoRatio: 2,
                robotPos: {x: 159,y: 564,width: 279,height: 496},
                renderType: 0
            }
        })
        let clickMenu=function(n){
            index.value=n
        }



        

        // 分页
        let pageSizeOptions=ref(['5','10','15','20'])
        let onShowSizeChange=function(current,pageSize){
            page.limit=pageSize
            page.page=1
            getList()
        }
        let page2=reactive({limit:10,page:1,total:""})
        let onShowSizeChange2=function(current,pageSize){
            page2.limit=pageSize
            page2.page=1
            getaiList()
        }

        // AI脚本
        let aitextOpen=ref(false)
        let openAitext=function(){
            aitextOpen.value=true
        }
        let aiActive=ref("1")
        let formstate=reactive({data:{}})
        let formState1=reactive({title:"",introduction:"",promotion_scene:"短视频带货",sell_point:"",
        effect:"",script_style:"不限",preferential:"",suit_crowd:"",user_pain_spot:"",suit_scene:"",script_word_count:"不限字数",script_type:""})
        let formState2=reactive({title:"",introduction:"",promotion_scene:"",sell_point:"",
        effect:"",script_style:"",preferential:"",suit_crowd:"",user_pain_spot:"",suit_scene:"",script_word_count:"不限字数",script_type:""})
        let formState3=reactive({title:"",introduction:"",promotion_scene:"",sell_point:"",
        effect:"",script_style:"",preferential:"",suit_crowd:"",user_pain_spot:"",suit_scene:"",script_word_count:"不限字数",script_type:""})
        let formState4=reactive({title:"",introduction:"",promotion_scene:"",sell_point:"",
        effect:"",script_style:"",preferential:"",suit_crowd:"",user_pain_spot:"",suit_scene:"",script_word_count:"不限字数",script_type:""})
        let formState5=reactive({title:"",introduction:"",promotion_scene:"",sell_point:"",
        effect:"",script_style:"",preferential:"",suit_crowd:"",user_pain_spot:"",suit_scene:"",script_word_count:"不限字数",script_type:""})
        

        // 生成脚本
        let aitext=ref()
        let aiid=ref()
        let isloading=ref(false)
        let creatText=function(text){
            isloading.value=true
            aitext.value=""
            if(aiActive.value==1){
                formstate.data=formState1 
            }else if(aiActive.value==2){
                formstate.data=formState2
            }else if(aiActive.value==3){
                formstate.data=formState3
            }else if(aiActive.value==4){
                formstate.data=formState4 
            }else if(aiActive.value==5){
                formstate.data=formState5 
            }
            formstate.data.script_type=text
            const key = 'updatable';
            message.loading({ content: '脚本生成中', key });
            proxy.$http.post('/index/aidr.script/add.html',{title:formstate.data.title,introduction:formstate.data.introduction,promotion_scene:formstate.data.promotion_scene,sell_point:formstate.data.sell_point
            ,effect:formstate.data.effect,script_style:formstate.data.script_style,preferential:formstate.data.preferential,suit_crowd:formstate.data.suit_crowd,user_pain_spot:formstate.data.user_pain_spot,
            suit_scene:formstate.data.suit_scene,script_word_count:formstate.data.script_word_count,script_type:formstate.data.script_type},{
            headers:{
                'token':sessionStorage.getItem("token")
            }
            }).then((res) => {
                if(res.data.code==1){
                isloading.value=false
                aitext.value=res.data.data
                aiid.value=res.data.id
                // console.log(res.data.data)
                // console.log(aitext.value)
                message.success({ content: '生成成功', key });

                }else{
                    isloading.value=false
                    message.error(res.data.msg);
                }
            });
            
        }

        // 保存
        let saveAitext=function(id){
            proxy.$http.post('/index/aidr.script/edit.html',{id:id,status:1},{
            headers:{
                'token':sessionStorage.getItem("token")
            }
            }).then((res) => {
                if(res.data.code==1){
                    message.success(res.data.msg);
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        let confrimAitext=function(){

            if(aitext._rawValue){
                
            }else{
                message.error('内容不能为空！');
                return false;
            }

            data.materials.script.scriptContent=aitext
            aiListOpen.value=false
            aitextOpen.value=false
        }
        let selectAItext=ref()
        let selectAilist=function(){

            if(selectAItext._rawValue){
                
            }else{
                message.error('内容不能为空！');
                return false;
            }

            if(selectAItext.value==""){
                message.error("请选择脚本");
                return false
            }
            aiListOpen.value=false
            data.materials.script.scriptContent=selectAItext
            

        }

        // 脚本列表
        let aiListOpen=ref(false)
        let aiList=reactive({arr:[]})
        let getaiList=function(){
            proxy.$http.post('/index/aidr.script/index.html',{page:page2.page,limit:page2.limit},{
            headers:{
                'token':sessionStorage.getItem("token")
            }
            }).then((res) => {
                if(res.data.code==0){
                    aiList.arr=res.data.data
                    page2.total=res.data.count
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        let openAilist=function(){
            aiListOpen.value=true
            getaiList()
        }

        let isedit=ref(false)
        let openedit=function(){
            isedit.value=true
           
        }
        let closeedit=function(){
            console.log(1111)
            isedit.value=false
        }

        // 会员
        let currentType=ref(1)
        let currentTime=ref()
        let mvisible=ref(false)
        let tcList=reactive({data:[]})
        // 套餐列表
        let getTc=function(){
            proxy.$http.post('/index/aidr.setMeal/index.html',{limit:10},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
               
                if(res.data.code==0){
                    tcList.data=res.data.data
                    
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTc()

        let currenPay=ref()
        let selectType=function(num,pay){
            // if(num==6){
            // currentTime.value=moment(moment().format('YYYY-MM-DD')).add(7, 'days').format('YYYY-MM-DD')
            // }else if(num==5){
            // currentTime.value=moment(moment().format('YYYY-MM-DD')).add(1, 'month').format('YYYY-MM-DD')
            // }else if(num==4){
            // currentTime.value=moment(moment().format('YYYY-MM-DD')).add(1, 'year').format('YYYY-MM-DD')
            // }
            currentType.value=num
            currenPay.value=pay
            addOrder(num)
        }
        
        let openMember=function(){
            mvisible.value=true
            selectType(tcList.data[0].id,tcList.data[0].total_price)
        }
        // 添加订单
        let iscode=ref(true)
        let orderInfo=reactive({data:{}})
        let addOrder=function(id){
            proxy.$http.post('/index/aidr.index/addorder.html',{set_meal_id:id,order_amount:currenPay.value},{
            headers:{
                'token':sessionStorage.getItem("token")
            }
            }).then((res) => {
                if(res.data.code==1){

                orderInfo.data=res.data.data
               
                
                }else if(res.data.status==2002){
                    loginvisible.value=true
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        // 人像拖拽
        let onDragStop=function (x, y) {
            console.log(x,y)
            if(x<0){
                data.renderOption.robotPos.x=0
                
            }if(y<0){
                data.renderOption.robotPos.y=0
            }
            data.renderOption.robotPos.x=parseInt(x*3.145)
            data.renderOption.robotPos.y=parseInt(y*3.145)

            // data.renderOption.robotPos.x=parseInt(x)
            // data.renderOption.robotPos.y=parseInt(y)

            console.log('拖拽',data.renderOption.robotPos.x, data.renderOption.robotPos.y, data.renderOption.robotPos.width, data.renderOption.robotPos.height);

            // this.dragging = false;
        }
        // 人像缩放
        let onResizeStop= function (x, y, width, height) {
            // this.resizing = false;
            console.log(x, y, width, height);
            if(x<0){
                data.renderOption.robotPos.x=0
                
            }if(y<0){
                data.renderOption.robotPos.y=0
            }
            
            data.renderOption.robotPos.x=parseInt(x*3.145)
            data.renderOption.robotPos.y=parseInt(y*3.145)
            data.renderOption.robotPos.width=parseInt(width*3.145)
            data.renderOption.robotPos.height=parseInt(height*3.145)

            // data.renderOption.robotPos.x=parseInt(x)
            // data.renderOption.robotPos.y=parseInt(y)
            // data.renderOption.robotPos.width=parseInt(parseInt(width)*3.11)
            // data.renderOption.robotPos.height=parseInt(parseInt(height)*3.11)

            console.log('缩放',data.renderOption.robotPos.x, data.renderOption.robotPos.y, data.renderOption.robotPos.width, data.renderOption.robotPos.height);




        }


        // 任务列表

        let taskList=reactive({arr:[]})
        let page=reactive({page:1,limit:20,total:""})
        let getList=function(){
            
             proxy.$http.post('/index/aidr.aidrVideo/index.html',{page:page.page,limit:page.limit},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
               
                if(res.data.code==0){
                    taskList.arr=res.data.data
                    page.total=res.data.count
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        // 视频预览
        let closeVideo=function(){
            vivwerurl.value=""
        }
        let ismask=ref(-1)
        let handleMouseEnter=function(i){
            ismask.value=i
        }
        let handleMouseLeave=function(i){
            ismask.value=-1
        }
        let vivwerurl=ref()
        let vivwerVide=function(url){
            vivwerurl.value=url
        }
//打开视频窗口
        let openVideo=function(url){
            window.open(url, 'Google', 'width=540,height=960');
        }

        
        // getList()

        let downUrl=function(url,urlName){
            saveAs.saveAs(url,urlName)

        }
        let loading=ref(false)
        // 生成视频
        let creatVideo=function(){
            console.log(aiIndex);
            // alert(aiIndex);
            var data2=JSON.parse(JSON.stringify(data));
            var ai_common_id = data2.materials.robotId;

            var dub_common_id = data2.materials.voiceId;
            var script_content = data.materials.script.scriptContent;
            if(!script_content){
                message.warning("请输入文本");
                return false
            }
            if(!ai_common_id){
                message.warning("请选择数字人");
                return false
            }
            if(!dub_common_id){
                message.warning("请选择配音");
                return false
            }



            // var iWidth = 100; //弹出窗口的宽度;
            // var iHeight = 100; //弹出窗口的高度;

            // var iTop = (window.screen.height-30-iHeight)/2; //获得窗口的垂直位置;
            // var iLeft = (window.screen.width-10-iWidth)/2; //获得窗口的水平位置;


            // var iTop = window.screen.height+100;
            // var iLeft = window.screen.width+100;
       
            var createaidrdp_url = '/index/aidr.index/createaidrdpadd.html?token='+sessionStorage.getItem("token")+'&ai_type='+aiIndex+'&ai_common_id='+ai_common_id+'&dub_common_id='+dub_common_id+'&script_content='+script_content;
            
           
            
            // var myWindow = window.open('http://aidpapi.13524.net'+createaidrdp_url, 'Google', 'width='+iWidth+',height='+iWidth+',top='+iTop+',left='+iLeft+',titlebar=no,toolbar=no,location=no,menubar=no,menubar=no');
            
           


            // myWindow.focus(); // 将焦点移到新窗口以防其他行为

            proxy.$http.get(createaidrdp_url,{page:page.page,limit:page.limit},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {

            });

                    loading.value=false
                    let secondsToGo = 8;
                    const modal = Modal.success({
                        title: '提交成功',
                        content: `正在生成视频，预计5分钟左右完成，请到：右上角任务列表中查看进度并下载，弹框${secondsToGo}秒后关闭`,
                    });
                    const interval = setInterval(() => {
                        secondsToGo -= 1;
                        modal.update({
                        content: `正在生成视频，预计5分钟左右完成，请到：右上角任务列表中查看进度并下载，弹框${secondsToGo}秒后关闭`,

                        });
                    }, 1000);
                    setTimeout(() => {
                        clearInterval(interval);
                        modal.destroy();
                    }, secondsToGo * 1000);

return false;





            if(data2.wordArtStyle.fontUri==""){
                delete data2.wordArtStyle
            }else{
                data2.wordArtStyle.fontSize=parseInt(formData.fontsize)*3.1
            }
            if(data2.renderOption.robotPos.y>782){
                data2.renderOption.robotPos.y=782
            }
            if(data2.renderOption.robotPos.x>440){
                data2.renderOption.robotPos.x=440
            }

            
            // console.log(customVideoValue.length);
            // return false;


            // console.log(data)
            data2.materials.title="智能成片_"+moment().format("YYYYMMDD_HHmmss")
            
        
            // return  false
            if(data.materials.script.scriptContent==""){
                message.warning("请输入脚本");
                return false
            }else if(data2.materials.voiceId==""){
                message.warning("请选择配音");
                return false
            }
            
            if(data2.materials.robotId==""&&!customVideoValue){
                // message.warning("请选择数字人或视频");
                message.warning("请选择数字人"); 
                return false
            }

            console.log(customVideoValue.length);

            if(customVideoValue.length>0){
                if(customVideoValue.length<3){
                    message.warning("请选择视频不少于3个");
                    return false
                }
            }

            if(data2.materials.robotId!=""&&!(customVideoValue.length>0)){
                data2.renderOption.renderType = 0;//数字人
            }else if(data2.materials.robotId==""&&(customVideoValue.length>0)){
                data2.renderOption.renderType = 1;//自定义视频
                 delete data2.materials.bg
                 delete data2.materials.robotId
            }else if(data2.materials.robotId!=""&&(customVideoValue.length>0)){
                  data2.renderOption.renderType = 2;//数字人+自定义视频
            }

            // data2.videos = Object.entries(customVideoValue);
            // data2.videos = Object.fromEntries(Object.entries(customVideoValue));

            data2.materials.videos = JSON.parse(JSON.stringify(customVideoValue));

            // console.log(data2);
            // return false;
            
            
            loading.value=true
            proxy.$http.post('/index/aidr.index/createaidr.html',{data2},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.code==200){
                    selectAItext.value=""
                    loading.value=false
                    let secondsToGo = 8;
                    const modal = Modal.success({
                        title: '提交成功',
                        content: `正在生成视频，预计5分钟左右完成，请到：右上角任务列表中查看进度并下载，弹框${secondsToGo}秒后关闭`,
                    });
                    const interval = setInterval(() => {
                        secondsToGo -= 1;
                        modal.update({
                        content: `正在生成视频，预计5分钟左右完成，请到：右上角任务列表中查看进度并下载，弹框${secondsToGo}秒后关闭`,

                        });
                    }, 1000);
                    setTimeout(() => {
                        clearInterval(interval);
                        modal.destroy();
                    }, secondsToGo * 1000);
                    
                    // message.success("提交成功，正在生成视频，预计5分钟左右完成，请到任务列表中查看进度并下载");
                }else{
                    loading.value=false
                    message.error(res.data.msg);
                }
            });
        }

        // 脚本
     

        // 数字人选择(公共)
        let digitalList=reactive({arr:[]})

        
        let digitalList0=''
        let digitalList1=''
        let digitalList2=''
        //全部(数字人)（公共）
        proxy.$http.post('/index/aidr.ai_common/index.html',{page:1,limit:500},{
            headers:{
                'token':sessionStorage.getItem("token")
            }
        }).then((res) => {
            console.log(res.data.data);
          

            digitalList0=reactive(res.data.data);
            //
            digitalList.arr=digitalList0

            /*****默认数字人start*****/
            setTimeout(() => {
                        // digitalChange(digitalList0[0].id,digitalList0[0].imgurl_thum,digitalList0[0].sex,digitalList0[0].name,digitalList0[0].age);
                    }, 0);
            /*****默认数字人end*****/

        });
        //男（数字人）
        proxy.$http.post('/index/aidr.ai_common/index.html',{page:1,limit:500,filter:"{\"sex\":1}"},{
            headers:{
                'token':sessionStorage.getItem("token")
            }
        }).then((res) => {
            // console.log(res.data.data);

            digitalList1=reactive(res.data.data);

        });
        //女（数字人）
        proxy.$http.post('/index/aidr.ai_common/index.html',{page:1,limit:500,filter:"{\"sex\":2}"},{
            headers:{
                'token':sessionStorage.getItem("token")
            }
        }).then((res) => {
            // console.log(res.data.data);

            digitalList2=reactive(res.data.data);

        });



        // let digitalList0=reactive([
        //     {id:"Hanyue-Cloth0",sex:2,name:"韩悦",age:"20~30岁",num:"4876",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/oGf1703223537mVWSuQ.png"},
        //     {id:"Yingtao-Doctor",sex:2,name:"瑞欣",age:"20~30岁",num:"4359",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/sfk1703223541GDsVLY.png"},
        //     {id:"Xiangxueyao-Look1",sex:2,name:"王秀珍",age:"40~50岁",num:"4671",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/CQD1703223537qsbzvl.png"},
        //     {id:"Yingtao-Suit",sex:2,name:"瑞琳",age:"20~30岁",num:"5230",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/KCO1703223539jnzYIG.png"},
        //     {id:"Shiqi-Cloth1-Half",sex:2,name:"十七",age:"20~30岁",num:"6028",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/lCk1703223536stakEB.png"},
        //     {id:"Yingtao-Airline",sex:2,name:"瑞娜",age:"20~30岁",num:"4821",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/wdZ1703223540ZwPmCI.png"},
        //     {id:"Hanyue-Cloth1",sex:2,name:"韩芮",age:"20~30岁",num:"5429",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/QkP1703223537CcYSoK.png"},
        //     {id:"Yudongwei-Look1",sex:1,name:"赵同庆",age:"50岁以上",num:"4015",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/QnM1703223539sevjiw.png"},
        //     {id:"Yudongwei-Look2",sex:1,name:"赵老四",age:"50岁以上",num:"6841",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/Uwy1703223539JGpMQs.png"},
        //     {id:"Yudongwei-Look3",sex:1,name:"赵平川",age:"50岁以上",num:"6244",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/tLW1703223540IvOMiB.png"},
        //     {id:"Yudongwei-Look4",sex:1,name:"赵正广",age:"50岁以上",num:"4621",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/Yes1703223540oDAPfX.png"},
        //     {id:"Yingtao-OL",sex:2,name:"瑞仪",age:"20~30岁",num:"4221",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/SKt1703223539mwWTgu.png"},
        //     {id:"Xiangxueyao-Look2",sex:2,name:"王淑华",age:"40~50岁",num:"6571",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/lED1703223538QZuedy.png"},
        //     {id:"Xiangxueyao-Look3",sex:2,name:"王芳芳",age:"40~50岁",num:"4003",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/ytJ1703223538uOAnir.png"},
        //     {id:"Xiangxueyao-Look4",sex:2,name:"王婉英",age:"40~50岁",num:"5359",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/UzO1703223537XmLWnF.png"},
        //     {id:"Xiangxueyao-Look5",sex:2,name:"王玉凤",age:"40~50岁",num:"4781",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/TMq1703223538QecKzd.png"},
        //     {id:"Taozi-Look1",sex:2,name:"刘傅涵",age:"30~40岁",num:"5274",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/svi1703223537LQOTCm.png"},
        //     {id:"Taozi-Look2",sex:2,name:"刘欣怡",age:"30~40岁",num:"6035",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/qZG1703223540beNniq.png"},
        //     {id:"Taozi-Look3",sex:2,name:"刘淑华",age:"30~40岁",num:"5102",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/ADV1703223537IdceZj.png"},
        //     {id:"Taozi-Look4",sex:2,name:"刘静宜",age:"30~40岁",num:"4452",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/yaW1703223537vUgFil.png"},
        //     {id:"Taozi-Look5",sex:2,name:"刘优优",age:"30~40岁",num:"5887",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/gNy1703223537MKhDxu.png"},
        //     {id:"Taozi-Look6",sex:2,name:"刘仪曼",age:"30~40岁",num:"4039",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/DTS1703223537kGrxsz.png"},
        // ])

        // let digitalList1=reactive([
        //     {id:"Yudongwei-Look1",sex:1,name:"赵同庆",age:"50岁以上",num:"4015",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/QnM1703223539sevjiw.png"},
        //     {id:"Yudongwei-Look2",sex:1,name:"赵老四",age:"50岁以上",num:"6841",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/Uwy1703223539JGpMQs.png"},
        //     {id:"Yudongwei-Look3",sex:1,name:"赵平川",age:"50岁以上",num:"6244",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/tLW1703223540IvOMiB.png"},
        //     {id:"Yudongwei-Look4",sex:1,name:"赵正广",age:"50岁以上",num:"4621",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/Yes1703223540oDAPfX.png"},
        // ])

        // let digitalList2=reactive([
        //     {id:"Hanyue-Cloth0",sex:2,name:"韩悦",age:"20~30岁",num:"4876",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/oGf1703223537mVWSuQ.png"},
        //     {id:"Yingtao-Doctor",sex:2,name:"瑞欣",age:"20~30岁",num:"4359",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/sfk1703223541GDsVLY.png"},
        //     {id:"Xiangxueyao-Look1",sex:2,name:"王秀珍",age:"40~50岁",num:"4671",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/CQD1703223537qsbzvl.png"},
        //     {id:"Yingtao-Suit",sex:2,name:"瑞琳",age:"20~30岁",num:"5230",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/KCO1703223539jnzYIG.png"},
        //     {id:"Shiqi-Cloth1-Half",sex:2,name:"十七",age:"20~30岁",num:"6028",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/lCk1703223536stakEB.png"},
        //     {id:"Yingtao-Airline",sex:2,name:"瑞娜",age:"20~30岁",num:"4821",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/wdZ1703223540ZwPmCI.png"},
        //     {id:"Hanyue-Cloth1",sex:2,name:"韩芮",age:"20~30岁",num:"5429",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/QkP1703223537CcYSoK.png"},
        //     {id:"Yingtao-OL",sex:2,name:"瑞仪",age:"20~30岁",num:"4221",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/SKt1703223539mwWTgu.png"},
        //     {id:"Xiangxueyao-Look2",sex:2,name:"王淑华",age:"40~50岁",num:"6571",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/lED1703223538QZuedy.png"},
        //     {id:"Xiangxueyao-Look3",sex:2,name:"王芳芳",age:"40~50岁",num:"4003",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/ytJ1703223538uOAnir.png"},
        //     {id:"Xiangxueyao-Look4",sex:2,name:"王婉英",age:"40~50岁",num:"5359",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/UzO1703223537XmLWnF.png"},
        //     {id:"Xiangxueyao-Look5",sex:2,name:"王玉凤",age:"40~50岁",num:"4781",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/TMq1703223538QecKzd.png"},
        //     {id:"Taozi-Look1",sex:2,name:"刘傅涵",age:"30~40岁",num:"5274",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/svi1703223537LQOTCm.png"},
        //     {id:"Taozi-Look2",sex:2,name:"刘欣怡",age:"30~40岁",num:"6035",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/qZG1703223540beNniq.png"},
        //     {id:"Taozi-Look3",sex:2,name:"刘淑华",age:"30~40岁",num:"5102",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/ADV1703223537IdceZj.png"},
        //     {id:"Taozi-Look4",sex:2,name:"刘静宜",age:"30~40岁",num:"4452",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/yaW1703223537vUgFil.png"},
        //     {id:"Taozi-Look5",sex:2,name:"刘优优",age:"30~40岁",num:"5887",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/gNy1703223537MKhDxu.png"},
        //     {id:"Taozi-Look6",sex:2,name:"刘仪曼",age:"30~40岁",num:"4039",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/DTS1703223537kGrxsz.png"},
        // ])

        // digitalList.arr=digitalList0
        let changDigital=function(e){
            // console.log(JSON.stringify(e))
            if(e.target.value==='0'){
                digitalList.arr=digitalList0
                // digitalChange(digitalList0[0].id,digitalList0[0].imgurl_thum,digitalList0[0].sex,digitalList0[0].name,digitalList0[0].age);
            }else if(e.target.value==='1'){
                digitalList.arr=digitalList1
                // digitalChange(digitalList1[0].id,digitalList1[0].imgurl_thum,digitalList1[0].sex,digitalList1[0].name,digitalList1[0].age);
            }else if(e.target.value==='2'){
                digitalList.arr=digitalList2
                //  digitalChange(digitalList2[0].id,digitalList2[0].imgurl_thum,digitalList2[0].sex,digitalList2[0].name,digitalList2[0].age);
            }

            


        }
        let digitalSextxt=ref()
        let digitalNametxt=ref()
        let digitalAgetxt=ref()
        let digitalChange=function(id,url,sex,name,age){
            digitalSextxt.value=sex
            digitalNametxt.value=name
            digitalAgetxt.value=age
            data.materials.robotId=id
            formData.digital=url


            
            formDataZdy.digital = url;//选择的数字人或视频
            formDataZdy.bg_image_url =  formData.bg_image_url;
            

            // if(formData.bg_image_url==""){
            //     /*****默认背景start*****/
            //     bgChange(require('@/assets/bg1.png'),"icc/smartClip/background/v2.png")
            //     /*****默认背景end*****/
            //     // formData.bg_image_url= require('@/assets/bg1.png')
            //     // data.materials.bg.resource="icc/smartClip/background/v2.png"
            // }
        }



        //数字人个人
        let digitalPrivateList=reactive({arr:[]})
        let digitalList01 = '';
        const fileList = ref([]);
        // const handleChange = info => {
        let handleChange=function(info){
            const status = info.file.status;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                console.log('done');
                console.log(info.file, info.fileList);
                //添加个人视频
 

                proxy.$http.post('/index/aidr.ai_private/add.html',{video_url:info.file.response.data.url},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    console.log(res)
                    //个人视频列表
                    proxy.$http.post('/index/aidr.ai_private/index.html',{page:1,limit:500},{
                        headers:{
                            'token':sessionStorage.getItem("token")
                        }
                    }).then((res) => {
                        console.log(res.data.data);
                        digitalList01=reactive(res.data.data);
                        digitalPrivateList.arr=digitalList01
                    });
                });

                

                message.success(`${info.file.name} 上传成功.`);
            } else if (status === 'error') {
                console.log('error');
                message.error(`${info.file.name} file upload failed.`);
            }
        };
        let handleDrop = function(e) {
            console.log('handleDrop');
            console.log(e);
        }
        // 数字人选择(个人)
        proxy.$http.post('/index/aidr.ai_private/index.html',{page:1,limit:500},{
            headers:{
                'token':sessionStorage.getItem("token")
            }
        }).then((res) => {
            console.log(res.data.data);
            digitalList01=reactive(res.data.data);
            digitalPrivateList.arr=digitalList01
        });








        // /*****默认数字人start*****/
        // digitalChange("Yingtao-Suit","https://wxkjos.oss-cn-hangzhou.aliyuncs.com/adpush/20231222/KCO1703223539jnzYIG.png",2,"瑞琳","20~30岁")
        // /*****默认数字人end*****/

        //背景选择
        let bgList=reactive({arr:[]})
        // let bgChange='';
        proxy.$http.post('/index/aidr.ai_background/index.html',{page:1,limit:500},{
            headers:{
                'token':sessionStorage.getItem("token")
            }
        }).then((res) => {
            
            // console.log(8885);
            console.log(res.data.data);

            bgList.arr = res.data.data;

            bgChange(res.data.data[0].id,res.data.data[0].imgurl_thum)

            // console.log(bgList)

        });

        let bgChange=function(id,url){
            data.materials.bg.resource=id
            formData.bg_image_url=url

            formDataZdy.bg_image_url = url
        }


        // // // 背景选择
        // let bgList=reactive([
        //     {id:"icc/smartClip/background/v1.png",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/static_easyadmin/20231221/VJR1703128720XHRmIJ.png"},
        //     {id:"icc/smartClip/background/v2.png",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/static_easyadmin/20231221/Iwn1703128719hHKaEW.png"},
        //     {id:"icc/smartClip/background/v3.png",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/static_easyadmin/20231221/IzH1703128720KSzJdg.png"},
        //     {id:"icc/smartClip/background/v4.png",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/static_easyadmin/20231221/OlQ1703128721vDOlHp.png"},
        //     {id:"icc/smartClip/background/v5.png",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/static_easyadmin/20231221/ZOw1703128721VLbSql.png"},
        //     {id:"icc/smartClip/background/v6.png",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/static_easyadmin/20231221/vdZ1703128723KGZSLQ.png"},
        //     {id:"icc/smartClip/background/v7.png",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/static_easyadmin/20231221/wlH1703128722DVHPLZ.png"},
        //     {id:"icc/smartClip/background/v8.png",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/static_easyadmin/20231221/ogr1703128722fvBMuP.png"},
        //     {id:"icc/smartClip/background/v9.png",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/static_easyadmin/20231221/LXv1703128723gpLmqR.png"},
        //     {id:"icc/smartClip/background/v10.png",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/static_easyadmin/20231221/EuC1703128723TQeDfL.png"},
        //     {id:"icc/smartClip/background/v11.png",imgurl_thum:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/static_easyadmin/20231221/DWv1703128723ScbFNp.png"}
        // ])
        // let bgChange=function(id,url){
        //     console.log(9999)
        //     data.materials.bg.resource=id
        //     formData.bg_image_url=url
        // }



        //自定义视频
        let customVideoList=reactive({arr:[]})
        let cvChange='';
        proxy.$http.post('/index/aidr.custom_video/index.html',{page:1,limit:500},{
            headers:{
                'token':sessionStorage.getItem("token")
            }
        }).then((res) => {
            
            console.log(res.data.data);
            customVideoList.arr = res.data.data;

            




            // bgList = data_cvlist;
            
            // for (var i=0;i<data_bglist.length;i++)
            // { 
            //     bgList = data_bglist[i];
            // }

            // console.log(bgList)

           
            

        });

        //选择自定义视频
        let videonumchecked = ref(0)
        let customVideoValue= reactive ([])
        let isShowNum = ref(true)
        let customVideo=function(vid,coverUrl,videoUrl){

        //    console.log(event.target.checked);

            customVideoValue.push({
                "resource": vid,
                "materialType": 2,
                "videoInfo": {
                    "videoUrl": videoUrl,
                    "coverUrl": coverUrl,
                    "vid": vid,
                    "width": 0,
                    "height": 0,
                    "duration": 0,
                    "ratio": 2,
                    "bitrate": "0",
                    "size": "0"
                }
            });

            if(!event.target.checked){//没有被选中（取消选中）
                // console.log(111);
                customVideoValue = customVideoValue.filter((item) => item.resource !== vid);
            }


            this.customVideoValueCheckedAiAndCus = customVideoValue//赋值选中的视频
            this.customVideoValueChecked = customVideoValue//赋值选中的视频
            


            videonumchecked.value = customVideoValue.length

            if(videonumchecked.value>=3){
                isShowNum.value = false
            }else{
                isShowNum.value = true
            }


            

            if(!formDataZdy.digital){
                formDataZdy.bg_image_url = coverUrl;
            }

            if(!customVideoValue[0]){
                formDataZdy.bg_image_url = formData.bg_image_url;
            }

            

            
           
            console.log(customVideoValue);

           
        //     if(!event.target.checked){//没有被选中（取消选中）
        //         customVideoValue = customVideoValue.filter((item) => item !== vid);

        //     }else{
        //         if(customVideoValue.indexOf(vid)==-1){
        //             console.log(1111)
        //             customVideoValue.push(vid);
        //         }
        //     }
        //    console.log(customVideoValue)
        }


        //选择底部数字人（视频）
        let videobottom=function(type,bg_image_url){
            // console.log(bg_image_url);
            if(type==1){//点击的数字人
                // console.log(333);

                formDataZdy.digital = formData.digital;//选择的数字人或视频
                formDataZdy.bg_image_url =  formData.bg_image_url;
            }else if(type==2){//点击自定义视频
                // console.log(4444);

                formDataZdy.digital = '';//选择的数字人或视频
                formDataZdy.bg_image_url =  bg_image_url;
            }

        }



        // 配音选择
        let testVideo=ref()
        let audioPlayer = ref(null);
        let videoMouseLeave=function(){
            
            audioPlayer.value.pause();
        }
        let videoleMouseEnter=function(url){
            testVideo.value=url
            audioPlayer.value.play();
        }
        
        let videoList=reactive({arr:[]})

        let videoList0=''
        let videoList1=''
        let videoList2=''

        //全部(配音)
        proxy.$http.post('/index/aidr.dub_common/index.html',{page:1,limit:500},{
            headers:{
                'token':sessionStorage.getItem("token")
            }
        }).then((res) => {
            // console.log(res.data.data);
            videoList0=reactive(res.data.data);
            videoList.arr=videoList0
            /*****默认数字人start*****/
            setTimeout(() => {
                        videoChange(videoList0[0].id,videoList0[0].desc,videoList0[0].url)
                    }, 0);
            /*****默认数字人end*****/
        });

        //男（配音）
        proxy.$http.post('/index/aidr.dub_common/index.html',{page:1,limit:500,filter:"{\"sex\":1}"},{
            headers:{
                'token':sessionStorage.getItem("token")
            }
        }).then((res) => {
            // console.log(res.data.data);
            videoList1=reactive(res.data.data);
        });

        //女（配音）
        proxy.$http.post('/index/aidr.dub_common/index.html',{page:1,limit:500,filter:"{\"sex\":2}"},{
            headers:{
                'token':sessionStorage.getItem("token")
            }
        }).then((res) => {
            // console.log(res.data.data);
            videoList2=reactive(res.data.data);
        });


       
        // let videoList0=reactive([
        //     {id:"BV700_streaming",type:"2",title:"通用温柔女声",desc:"女声-温柔",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/vlw1703304450nWVgRC.wav"},
        //     {id:"BV405_streaming",type:"2",title:"甜美女声",desc:"女声-温柔",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/Ybi1703304450gqAbRS.wav"},
        //     {id:"BV409_streaming",type:"2",title:"译制片女声",desc:"女声-译制腔",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/tqJ1703304451wPbtOn.wav"},
        // {id:"BV412_streaming",type:"2",title:"解说小美",desc:"女声-温柔",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/XIQ1703304449jMYogi.wav"},
        //     {id:"BV403_streaming",type:"2",title:"心灵鸡汤小姐姐",desc:"女声-抚慰心灵",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/mbB1703304451xwQUNa.wav"},
        //     {id:"BV402_streaming",type:"2",title:"促销女声",desc:"女声-激情促销",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/cdl1703304412dzQeRZ.wav"},
        // {id:"BV009_DPE_ParaTaco_streaming",type:"2",title:"知性女生",desc:"女声-知性大方",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/GxB1703304451wvFMQl.wav"},
        //     {id:"BV034_streaming",type:"2",title:"知性姐姐",desc:"女声-知性姐姐",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/DgG1703304451sQiZXy.wav"},
        // {id:"BV210_streaming",type:"2",title:"搞笑佟掌柜",desc:"女声-西安搞笑女",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/ZzI1703304449vumbES.wav"},
        // {id:"zh_female_zhixing",type:"2",title:"知心姐姐",desc:"女声-知性大方",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/fso1703304451LirgpR.wav"},
        //     {id:"zh_female_qiaopi",type:"2",title:"俏皮小妹",desc:"女声-俏皮小妹",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/gSC1703304449aEJBhz.wav"},
        // {id:"zh_female_tianmei",type:"2",title:"甜美女声",desc:"女声-甜美小妹",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/uhy1703304450icQNuL.wav"},
        //     {id:"zh_female_zhubo",type:"2",title:"女主播",desc:"女声-女主播",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/RXt1703304449lQvyXc.wav"},
        // {id:"zh_female_story",type:"2",title:"少儿故事",desc:"女声-读书故事",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/UQb1703304450inMSOu.wav"},
        // {id:"zh_female_xiaoqian",type:"2",title:"领家女孩",desc:"女声-女同学",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/TyK1703304449JFdwED.wav"},
        //     {id:"zh_female_xiaoyue",type:"2",title:"甜美悦悦",desc:"女声-甜美悦悦",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/paj1703304450IVnKrd.wav"},
        // {id:"zh_female_xiaoke",type:"2",title:"天津小可",desc:"女声-天津妹子",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/xHF1703304450kBEFSY.wav"},
        //     {id:"zh_female_dbqiangshi",type:"2",title:"东北强势妹",desc:"女声-东北强势妹",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/sut1703304448zsLbUG.wav"},
        // {id:"zh_female_changshengpopo",type:"2",title:"婆婆",desc:"女声-婆婆",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/qzm1703304449TaRFuQ.wav"},
        // {id:"zh_female_wuzetian",type:"2",title:"武则天",desc:"女声-武则天",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/IKz1703304450XticIn.wav"},
        //     {id:"BV701_streaming",type:"1",title:"磁性男生",desc:"男声-磁性",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/lLX1703304479xaKoFv.wav"},
        // {id:"BV063_streaming",type:"1",title:"卡通海绵宝宝",desc:"男声-海绵宝宝",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/Wtl1703304480iutqvD.wav"},
        //     {id:"BV408_streaming",type:"1",title:"译制片男声",desc:"男声-译制腔",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/yeW1703304481emTNDo.wav"},
        // {id:"BV410_streaming",type:"1",title:"活力百科男生",desc:"男声-活力叙述",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/smp1703304479DeRKNG.wav"},
        // {id:"BV411_streaming",type:"1",title:"解说小帅",desc:"解说男人小帅",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/Vdy1703304479XGSmew.wav"},
        //     {id:"BV107_streaming",type:"1",title:"雅痞青叔",desc:"男声-优雅痞帅",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/cSi1703304481LDqBkX.wav"},
        // {id:"BV401_streaming",type:"1",title:"促销男声",desc:"男声-激情促销",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/wAJ1703304479GgeAXD.wav"},
        //     {id:"BV008_DPE_ParaTaco_streaming",type:"1",title:"亲切男声",desc:"男声-亲切小哥",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/hMf1703304480VTkduA.wav"},
        // {id:"BV061_GGL_EN_streaming",type:"1",title:"天才童声",desc:"男声-天才童声",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/eYw1703304480dTCiaM.wav"},
        // {id:"BV033_streaming",type:"1",title:"温柔小哥",desc:"男声-温柔小哥",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/yYO1703304481blUBCf.wav"},
        //     {id:"BV213_streaming",type:"1",title:"广西表哥",desc:"男声-广西表哥",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/BsE1703304479XJGhWm.wav"},
        // {id:"BV026_streaming",type:"1",title:"港剧男神",desc:"男声-粤语TVB",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/rBu1703304479HqDKLC.wav"},
        //     {id:"zh_male_qinqie",type:"1",title:"贴心小哥",desc:"男声-亲切贴心",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/pNh1703304480PrFGmE.wav"},
        // {id:"zh_male_ad",type:"1",title:"营销小哥",desc:"男声-营销小哥",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/Ral1703304481RpJByI.wav"},
        // {id:"zh_male_zhubo",type:"1",title:"男主播",desc:"男声-男主播",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/kQa1703304480xQuWkS.wav"},
        //     {id:"zh_male_huoli",type:"1",title:"温柔男声",desc:"男声-温柔小哥哥",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/Crt1703304480ZvGufM.wav"},
        // {id:"zh_male_xiaojian",type:"1",title:"邻家小哥",desc:"男声-男同学",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/GZT1703304480oqKrxE.wav"},

        //     {id:"zh_male_yuanqizhengtai",type:"1",title:"元气正太",desc:"男声-元气正太",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/fqd1703304481qtbEdS.wav"},

        // {id:"zh_male_xionger",type:"1",title:"熊二",desc:"男声-熊二",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/Vfp1703304481HBTXyA.wav"},
        // {id:"zh_male_silang",type:"1",title:"四郎",desc:"男声-四郎",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/gKp1703304480aWwZrA.wav"},
        //     {id:"zh_male_changtianyi_xuanyi",type:"1",title:"悬疑解说",desc:"男声-悬疑解说",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/Wkq1703304481HXKhFw.wav"}
        // ])
        // let videoList1=reactive([
        //     {id:"BV701_streaming",type:"1",title:"磁性男生",desc:"男声-磁性",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/lLX1703304479xaKoFv.wav"},{id:"BV063_streaming",type:"1",title:"卡通海绵宝宝",desc:"男声-海绵宝宝",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/Wtl1703304480iutqvD.wav"},
        //     {id:"BV408_streaming",type:"1",title:"译制片男声",desc:"男声-译制腔",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/yeW1703304481emTNDo.wav"},{id:"BV410_streaming",type:"1",title:"活力百科男生",desc:"男声-活力叙述",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/smp1703304479DeRKNG.wav"},{id:"BV411_streaming",type:"1",title:"解说小帅",desc:"解说男人小帅",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/Vdy1703304479XGSmew.wav"},
        //     {id:"BV107_streaming",type:"1",title:"雅痞青叔",desc:"男声-优雅痞帅",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/cSi1703304481LDqBkX.wav"},{id:"BV401_streaming",type:"1",title:"促销男声",desc:"男声-激情促销",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/wAJ1703304479GgeAXD.wav"},
        //     {id:"BV008_DPE_ParaTaco_streaming",type:"1",title:"亲切男声",desc:"男声-亲切小哥",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/hMf1703304480VTkduA.wav"},{id:"BV061_GGL_EN_streaming",type:"1",title:"天才童声",desc:"男声-天才童声",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/eYw1703304480dTCiaM.wav"},{id:"BV033_streaming",type:"1",title:"温柔小哥",desc:"男声-温柔小哥",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/yYO1703304481blUBCf.wav"},
        //     {id:"BV213_streaming",type:"1",title:"广西表哥",desc:"男声-广西表哥",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/BsE1703304479XJGhWm.wav"},{id:"BV026_streaming",type:"1",title:"港剧男神",desc:"男声-粤语TVB",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/rBu1703304479HqDKLC.wav"},
        //     {id:"zh_male_qinqie",type:"1",title:"贴心小哥",desc:"男声-亲切贴心",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/pNh1703304480PrFGmE.wav"},{id:"zh_male_ad",type:"1",title:"营销小哥",desc:"男声-营销小哥",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/Ral1703304481RpJByI.wav"},{id:"zh_male_zhubo",type:"1",title:"男主播",desc:"男声-男主播",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/kQa1703304480xQuWkS.wav"},
        //     {id:"zh_male_huoli",type:"1",title:"温柔男声",desc:"男声-温柔小哥哥",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/Crt1703304480ZvGufM.wav"},{id:"zh_male_xiaojian",type:"1",title:"邻家小哥",desc:"男声-男同学",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/GZT1703304480oqKrxE.wav"},
        //     {id:"zh_male_yuanqizhengtai",type:"1",title:"元气正太",desc:"男声-元气正太",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/fqd1703304481qtbEdS.wav"},{id:"zh_male_xionger",type:"1",title:"熊二",desc:"男声-熊二",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/Vfp1703304481HBTXyA.wav"},{id:"zh_male_silang",type:"1",title:"四郎",desc:"男声-四郎",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/gKp1703304480aWwZrA.wav"},
        //     {id:"zh_male_changtianyi_xuanyi",type:"1",title:"悬疑解说",desc:"男声-悬疑解说",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/Wkq1703304481HXKhFw.wav"}
        // ])
        // let videoList2=reactive([
        //     {id:"BV700_streaming",type:"2",title:"通用温柔女声",desc:"女声-温柔",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/vlw1703304450nWVgRC.wav"},{id:"BV405_streaming",type:"2",title:"甜美女声",desc:"女声-温柔",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/Ybi1703304450gqAbRS.wav"},
        //     {id:"BV409_streaming",type:"2",title:"译制片女声",desc:"女声-译制腔",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/tqJ1703304451wPbtOn.wav"},{id:"BV412_streaming",type:"2",title:"解说小美",desc:"女声-温柔",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/XIQ1703304449jMYogi.wav"},
        //     {id:"BV403_streaming",type:"2",title:"心灵鸡汤小姐姐",desc:"女声-抚慰心灵",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/mbB1703304451xwQUNa.wav"},
        //     {id:"BV402_streaming",type:"2",title:"促销女声",desc:"女声-激情促销",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/cdl1703304412dzQeRZ.wav"},{id:"BV009_DPE_ParaTaco_streaming",type:"2",title:"知性女生",desc:"女声-知性大方",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/GxB1703304451wvFMQl.wav"},
        //     {id:"BV034_streaming",type:"2",title:"知性姐姐",desc:"女声-知性姐姐",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/DgG1703304451sQiZXy.wav"},{id:"BV210_streaming",type:"2",title:"搞笑佟掌柜",desc:"女声-西安搞笑女",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/ZzI1703304449vumbES.wav"},{id:"zh_female_zhixing",type:"2",title:"知心姐姐",desc:"女声-知性大方",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/fso1703304451LirgpR.wav"},
        //     {id:"zh_female_qiaopi",type:"2",title:"俏皮小妹",desc:"女声-俏皮小妹",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/gSC1703304449aEJBhz.wav"},{id:"zh_female_tianmei",type:"2",title:"甜美女声",desc:"女声-甜美小妹",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/uhy1703304450icQNuL.wav"},
        //     {id:"zh_female_zhubo",type:"2",title:"女主播",desc:"女声-女主播",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/RXt1703304449lQvyXc.wav"},{id:"zh_female_story",type:"2",title:"少儿故事",desc:"女声-读书故事",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/UQb1703304450inMSOu.wav"},{id:"zh_female_xiaoqian",type:"2",title:"领家女孩",desc:"女声-女同学",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/TyK1703304449JFdwED.wav"},
        //     {id:"zh_female_xiaoyue",type:"2",title:"甜美悦悦",desc:"女声-甜美悦悦",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/paj1703304450IVnKrd.wav"},{id:"zh_female_xiaoke",type:"2",title:"天津小可",desc:"女声-天津妹子",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/xHF1703304450kBEFSY.wav"},
        //     {id:"zh_female_dbqiangshi",type:"2",title:"东北强势妹",desc:"女声-东北强势妹",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/sut1703304448zsLbUG.wav"},{id:"zh_female_changshengpopo",type:"2",title:"婆婆",desc:"女声-婆婆",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/qzm1703304449TaRFuQ.wav"},{id:"zh_female_wuzetian",type:"2",title:"武则天",desc:"女声-武则天",url:"https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/IKz1703304450XticIn.wav"},
        // ])

        

        // videoList.arr=videoList0
        let changSex=function(e){

            if(e.target.value==='0'){
                videoList.arr=videoList0

                // videoChange("BV405_streaming","女声-温柔","https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/Ybi1703304450gqAbRS.wav")

                videoChange(videoList0[0].id,videoList0[0].desc,videoList0[0].url)

            }else if(e.target.value==='1'){
                videoList.arr=videoList1

                // videoChange("BV410_streaming","男声-活力叙述","https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/smp1703304479DeRKNG.wav")

                videoChange(videoList1[0].id,videoList1[0].desc,videoList1[0].url)
                

            }else if(e.target.value==='2'){
                videoList.arr=videoList2
                
                // videoChange("BV405_streaming","女声-温柔","https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/Ybi1703304450gqAbRS.wav")

                videoChange(videoList2[0].id,videoList2[0].desc,videoList2[0].url)
                
            }
        }
        let videotxt=ref()
        let videoChange=function(id,desc,url){
           
            data.materials.voiceId=id
            videotxt.value=desc
            testVideo.value=url
            // if (audioPlayer.value.paused) {
            //     audioPlayer.value.play();
            // }

            
           
        }

        // /*****默认配音start*****/
        // videoChange("BV405_streaming","女声-温柔","https://wxkjos.oss-cn-hangzhou.aliyuncs.com/aidigitrobot/20231223/Ybi1703304450gqAbRS.wav")
        // /*****默认配音end*****/
       
      
        // 任务列表
        let taskOpen=ref(false)
        let openTask=function(){
            taskOpen.value=true
            getList()
        }

        // 字体样式
        let fontSize=ref(20)
        let fontStyle=ref()
        let isFont=ref(false)
        let fontTxt=ref()
        let fontAligntxt=ref("居中")
        let fontList=reactive([
            {text:"站酷仓耳渔阳体",value:"TsangerYuYangT-W03",url:"icc/smartClip/fonts/TsangerYuYangT-W03.ttf"},
            {text:"站酷小薇LOGO体",value:"xiaowei-Regular",url:"icc/smartClip/fonts/xiaowei-Regular.otf"},
            {text:"站酷高端黑",value:"zcool-gdh-Regular",url:"icc/smartClip/fonts/zcool-gdh-Regular.ttf"},
            {text:"站酷酷黑体",value:"ZCOOL_KuHei-Regular",url:"icc/smartClip/fonts/ZCOOL_KuHei-Regular.ttf"}
        ])
        
        // 字体
        let fontChange=function(value,text,url){
            isFont.value=true
            data.wordArtStyle.name=value
            fontStyle.value=value
            data.wordArtStyle.cname=text
            data.wordArtStyle.fontUri=url
        }

        /*****默认字体start*****/
        fontChange("TsangerYuYangT-W03","站酷仓耳渔阳体","icc/smartClip/fonts/TsangerYuYangT-W03.ttf")
        /*****默认字体end*****/

        // 字体位置
        let fontStyleChange=function(e){
            if(e.target.value=="left"){
                fontAligntxt.value="左对齐"
            }else if(e.target.value=="center"){
                fontAligntxt.value="居中"
            }else if(e.target.value=="right"){
                fontAligntxt.value="右对齐"
            }
        }

        //秒转分钟
        let stoi=function(s){
            var i = (parseInt(s/60)>9)?parseInt(s/60):"0"+parseInt(s/60)+":"+parseInt(s%60);
            return i;
        }
        

        // 清空选项
        let cleanFont=function(){
            data.wordArtStyle.name=""
            data.wordArtStyle.cname=""
            data.wordArtStyle.fontUri=""
        }
        let cleanDigital=function(){
            data.materials.robotId=""
            data.materials.bg.resource=""
            formDataZdy.digital = '';//选择的数字人或视频
            if(customVideoValue[0]){
                formDataZdy.bg_image_url = customVideoValue[0].videoInfo.coverUrl;
            }
        }
        let cleanCustomVideo=function(){//清空自定义视频

            videonumchecked.value = 0;

            /******重新赋值*****/
            customVideoList.arr = []
            proxy.$http.post('/index/aidr.custom_video/index.html',{page:1,limit:500},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                console.log(res.data.data);
                customVideoList.arr = res.data.data;

            });
            /******重新赋值*****/

            customVideoValue =[];

            formDataZdy.bg_image_url = formData.bg_image_url;;
            if(customVideoValue[0]){
                formDataZdy.bg_image_url = customVideoValue[0].videoInfo.coverUrl;
            }
          


        }

        

        const router=useRouter()
        let quitLogin=function(){
            sessionStorage.setItem("token","")
            router.push({path:"/login"})
        }
        let isLogin=ref(sessionStorage.getItem("token"))

        let videoMinute=ref(0)
        let username=ref("个人信息")
        let getUserinfo=function(){
             proxy.$http.post('/index/aidr.user/page.html',{id:sessionStorage.getItem("id")},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
                }).then((res) => {
                    if(res.data.code==0){

                        videoMinute.value=res.data.data.video_surplus_no
                        username.value=res.data.data.username
                    
                    }
            });
        }
        getUserinfo()

        let closeDrawer=function(){
            aiListOpen.value=false
            aitextOpen.value=false
        }
        let textvalue=ref("123")
        
        return{
            textvalue,
            confrimAitext,selectAilist,selectAItext,isloading,aiid,saveAitext,
            closeDrawer,aitext,onShowSizeChange2,page2,
            aiListOpen,aiList,getaiList,openAilist,
            videoMinute,username,
            quitLogin,isLogin,
            aitextOpen,openAitext,formState1,formState2,formState3,formState4,formState5,aiActive,isedit,openedit,closeedit,creatText,
            mvisible,tcList,currentType,selectType,currentTime,addOrder,orderInfo,iscode,openMember,currenPay,
            pageSizeOptions,onShowSizeChange,page,
            handleMouseEnter,handleMouseLeave,ismask,vivwerurl,vivwerVide,closeVideo,
            changeFormdate,downUrl,
            taskList,getList,
            bgList,bgChange,
            customVideoList,cvChange,
            videoList,videoChange,videotxt,audioPlayer,testVideo,videoMouseLeave,videoleMouseEnter,changSex,
            digitalList,digitalChange,digitalSextxt,digitalNametxt,digitalAgetxt,changDigital,
            fontSize,fontStyle,isFont,fontChange,fontList,fontTxt,fontStyleChange,fontAligntxt,
            taskOpen,openTask,
            onDragStop,onResizeStop,
            formData,data,creatVideo,loading,
            cleanFont,cleanDigital,
            index,clickMenu,activeKey,search,
            customVideo,customVideoValue,
            videonumchecked,
            isShowNum,
            videobottom,
            stoi,
            formDataZdy,
            cleanCustomVideo,
            openVideo,
            handleChange,handleDrop,digitalPrivateList,handleTabChange,aiIndex
            
        }



    }
}
</script>